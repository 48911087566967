import React from 'react'

const Terms = () => {
  return (

    <div className='flex items-center justify-center p-14'>
        <div className='w-4/5 flex-col space-y-20'>
            <h1 className='font-light text-2xl underline'>
                TENDARLY T&C’s.
                HECKERBELLA LIMITED
            </h1>
            <div className='flex items-start justify-start flex-col space-y-6'>
                <h2 className='font-semibold text-2xl'>Terms & Conditions</h2>
                <article className='space-y-6'>
                    <b>Please read carefully these Terms and Conditions ( “Agreement”).</b>
                    <p>
                        Heckerbella Limited (“Company” or “we” or “us”) designed the Product (“Tendarly”) to foster
                        doctor-patient communications and simplify access to healthcare for everyone through mobile software
                        applications or documentations thereof (“Service”) under these Terms and Conditions.
                    </p>
                    <p className='font-semibold'>
                        This Agreement constitutes a legally binding agreement between each individual who installs, signs
                        up for or uses the Service and Heckerbella Limited. If you install and sign up for the Service, YOU
                        AGREE THAT YOU HAVE READ THIS AGREEMENT, UNDERSTAND IT, AND AGREE TO
                        BE BOUND BY ALL OF ITS TERMS AND CONDITIONS.
                    </p>
                    <p className='font-bold'>
                        IF YOU DO NOT AGREE TO THE TERMS OF THIS AGREEMENT, THEN DO NOT
                        INSTALL, SIGN UP OR USE THE SERVICE.
                    </p>
                </article>
            </div>

            <div className='flex items-start justify-start flex-col space-y-8'>
                <article className='space-y-2'>
                    <h2 className='font-semibold text-xl'>1. CHANGES TO THE TERMS AND CONDITIONS</h2>
                    <p className='flex items-start justify-start space-x-8'>
                        <span className='font-medium'>1.1</span> 
                        <p className=''>
                            The company may at its discretion modify, update, add to, discontinue, remove or otherwise change
                            these Terms and Conditions at any time. Each such modification will take immediate effect upon
                            notification to you. The company may provide you with notices, including those regarding material
                            changes to these Terms and Conditions, by email, regular mail, text message, in-app messaging, or
                            other reasonable means now known or hereinafter developed.
                        </p>
                    </p>

                    <p className='flex items-start justify-start space-x-8'>
                        <span className='font-medium'>1.2</span> 
                        <p>
                            Your continued use of the Service following any such notifications constitutes your acceptance of such
                            modifications and your agreement to be bound by these Terms and Conditions. If you do not agree to
                            any modification of these Terms and Conditions, your sole remedy is to discontinue your use and
                            uninstall the Service at any time.
                        </p>
                    </p>
                </article>

                <article className='space-y-2'>
                    <h2 className='font-semibold text-xl'>2. DESCRIPTION OF SERVICE</h2>
                    <p className='flex items-start justify-start space-x-8'>
                        <p className=''>
                            Tendarly is an innovative and cutting-edge platform designed to bridge the interaction gap between
                            users and doctors, and transform the way healthcare connections are conducted. By leveraging
                            advanced technology and user-friendly interfaces, Tendarly offers a distinct and efficient option for
                            patients to connect with doctors whilst ensuring quick responses and timely medical attention.
                            Through the use of the Service (web application and mobile software), doctors can register for
                            verification and engage with users as Tendarly's broadcast system offers quick access to available
                            doctors, thereby reducing waiting times for users.
                            Communication is made seamless as users can send symptom information, images, and files directly
                            through the chat interface. Tendarly maintains a comprehensive history of interactions, allowing users
                            and doctors to review past connections and its monetization model ensures doctors are compensated
                            for complete connections.
                        </p>
                    </p>
                </article>

                <article className='space-y-2'>
                    <h2 className='font-semibold text-xl'>3. GRANT OF SERVICE LICENSE</h2>
                    <p className='flex items-start justify-start space-x-8'>
                        <span className='font-medium'>3.1</span> 
                        <p className=''>
                            Subject to the terms of this Agreement, you are granted a limited, non-exclusive, non-transferable
                            (without the right to sublicense) and revocable license to install, access and use the Service for your
                            personal use. You agree that you obtain no rights other than the rights and licenses expressly granted in
                            this Agreement. Company reserves the right to change, upgrade or discontinue the Service and/or any
                            feature of the Service at any time, with or without notice. All rights not expressly granted under this
                            Agreement are reserved by the Company or its licensors. Users under 18 are not permitted to enter
                            into this Agreement or use the Service unless your parent or legal guardian understands and consents
                            to the terms of this Terms and Conditions. If you are a parent or legal guardian permitting a person
                            under the age of 18 (“Minor”) to use the Service, you agree to supervise the use and assume all
                            responsibilities and risks associated with the Minor’s use of the Service.
                        </p>
                    </p>
                </article>

                <article className='space-y-2'>
                    <h2 className='font-semibold text-xl'>4. SERVICE RESTRICTIONS</h2>
                    <p className='flex items-start justify-start space-x-8'>
                        <p className=''>
                            You agree that you will not, and will not permit others to: (i) damage, interfere with or unreasonably
                            overload the Service; (ii) introduce into the Service any code intended to disrupt; (iii) alter or delete any
                            information, data, text, links, images, software, chat, communications and other content available
                            through the Service (collectively, “Content”); (iv) access the Service by expert system, electronic agent,
                            “bot” or other automated means; (v) use scripts or disguised redirects to derive financial benefit from
                            Company; (vi) modify, reverse engineer, reverse assemble, decompile, copy or otherwise derive the
                            source code of the Service for any reason; (vii) rent, lease, lend, sell or sublicense any part of or
                            provide any commercial hosting with the Service; (viii) provide any unauthorized third party with
                            access to the Service; (ix) access or attempt to access confidential Content through the Service; (x)
                            interfere with the operation of the Service including, but not limited to, distribution of unsolicited
                            advertising or mail messages and propagation of computer worms and viruses; (xi) post any material in
                            any form whatsoever within the Service that is defamatory, obscene or otherwise unlawful or violates
                            any third party’s right of privacy or publicity; (xii) infringe any third party’s patent, copyright, service
                            mark, trademark or other intellectual property right of any kind or misappropriate the trade secrets of
                            any third party in connection with your use of the Service; (xiii) engage in any activity that does not
                            comply with applicable law and regulations or otherwise engage in any illegal, manipulative or
                            misleading activity through the use of the Service; (xiv) use the manual or automated software, devices
                            or other processes to "scrape," "crawl," "spider" or index any page of Content from the Service; (xv)
                            harass, threaten or harm any other user and/or violate its privacy.
                        </p>
                    </p>
                </article>

                <article className='space-y-2'>
                    <h2 className='font-semibold text-xl'>5 . RESERVATION OF RIGHTS AND OWNERSHIP</h2>
                    <p className='flex items-start justify-start space-x-8'>
                        <p className='flex items-start justify-start flex-col space-y-4'>
                            <p className=''>
                                The company may at its discretion modify, update, add to, discontinue, remove or otherwise change
                                these Terms and Conditions at any time. Each such modification will take immediate effect upon
                                notification to you. The company may provide you with notices, including those regarding material
                                changes to these Terms and Conditions, by email, regular mail, text message, in-app messaging, or
                                other reasonable means now known or hereinafter developed.
                            </p>
                            <p className=''>
                                The company may at its discretion modify, update, add to, discontinue, remove or otherwise change
                                these Terms and Conditions at any time. Each such modification will take immediate effect upon
                                notification to you. The company may provide you with notices, including those regarding material
                                changes to these Terms and Conditions, by email, regular mail, text message, in-app messaging, or
                                other reasonable means now known or hereinafter developed.
                            </p>
                        </p>
                    </p>
                </article>

                <article className='space-y-2'>
                    <h2 className='font-semibold text-xl'>6. PAYMENT AND REFUND POLICY</h2>
                    <p className='flex items-start justify-start space-x-8'>
                        <span className='font-medium'>6.1</span> 
                        <p className=''>
                            You acknowledge that Tendarly is a paid service and that the sum of N1000.00 (One thousand naira
                            only) will be paid for each connection to the Service.
                        </p>
                    </p>

                    <p className='flex items-start justify-start space-x-8'>
                        <span className='font-medium'>6.2</span> 
                        <p>
                            Refund will be made available only when you pay for a connection fee and (i) no doctor accepts your
                            request; (ii) you cancel the request before the connection is answered; and (iii) you get connected with a
                            doctor but the doctor is unresponsive and you cancel the connection. The unresponsive time must be
                            after 6 minutes from the beginning of the connection.
                        </p>
                    </p>

                    <p className='flex items-start justify-start space-x-8'>
                        <span className='font-medium'>6.3</span> 
                        <p>
                            Instances of no refund include when you pay for a connection, get connected with a doctor and (i)
                            receive a final note, thereby completing the connection; (ii) receive a final note and do not end the
                            connection; and (iii) have a conversation with the doctor but cancel the connection.
                        </p>
                    </p>
                </article>

                <article className='space-y-2'>
                    <h2 className='font-semibold text-xl'>7. SERVICE UPDATE/MAINTENANCE</h2>
                    <p className='flex items-start justify-start space-x-8'>
                        <p className=''>
                            The Company may, at any time, provide or make available updates or upgrades to the Service
                            (“Updates”), including without limitation to bug fixes, service enhancements, new features or
                            modifications of the Service. However, you acknowledge that the Company is not obliged to provide
                            you with updates. Updates will be governed by these Terms and Conditions unless separate terms and
                            conditions are provided with such updates, in which case such separate terms and conditions shall
                            govern the Updates. Updates for critical bug fixes or other improvements may be automatically
                            downloaded and installed given the importance of receiving such Updates promptly to help protect you
                            and other Users.

                            To the extent permitted by the law, the Company reserves the right to modify the use or disable the
                            access to the Service or any portion thereof without prior notice.
                        </p>
                    </p>
                </article>

                <article className='space-y-2'>
                    <h2 className='font-semibold text-xl'>8. PAYMENT AND REFUND POLICY</h2>
                    <p className='flex items-start justify-start space-x-8'>
                        <span className='font-medium'>8.1</span> 
                        <p className=''>
                            <b>Social Sign-On.  </b> 
                            When you access or use the Service, we may make available services from one or
                            more third parties (“Third-Party Platforms”). The Service supports Third-Party Platforms, including
                            Facebook and Google, to make it easier for you to sign in or create your Account. To the extent
                            permitted by law, the Company disclaims any warranty, condition or representation in respect of the
                            Third-Party platforms and/or services. Any use of Third-Party Platforms to create and access your
                            Account is subject to the terms and conditions, license agreements and privacy policies of such third
                            parties (“Third-Party Terms”).
                        </p>
                    </p>
                </article>

                <article className='space-y-2'>
                    <h2 className='font-semibold text-xl'>9. FEEDBACK POLICY</h2>
                    <p className='flex items-start justify-start space-x-8'>
                        <p className=''>
                            The Company values your insights and feedback, hence, you may be invited to provide us feedback,
                            comments, ideas, suggestions, reviews and other information about our Service (“Feedback”) through
                            various channels including but not limited to In-app chat and surveys, Socials, Support email, Support
                            phone line, App stores reviews via Google Play Store and Apple App Store. You hereby grant to the
                            Company, its affiliates and agents a nonexclusive, royalty-free, perpetual, irrevocable and fully
                            sub-licensable right to use, reproduce, modify, adapt, publish, perform, translate, create derivative
                            works from, distribute and display the Feedback in any media and for any legal purpose, including,
                            without limitation, the right to use such Feedback in advertising and promotional materials and to
                            enhance or improve our products and services and the products and services of its affiliates.
                        </p>
                    </p>
                </article>

                <article className='space-y-2'>
                    <h2 className='font-semibold text-xl'>10. COLLECTION AND USE OF DATA</h2>
                    <p className='flex items-start justify-start space-x-8'>
                        <p className=''>
                            The Company may collect and use information necessary to provide, improve or enhance the Service
                            and Updates. At all times your information will be treated in accordance with applicable laws.
                        </p>
                    </p>
                </article>

                <article className='space-y-2'>
                    <h2 className='font-semibold text-xl'>11. DATA ACCESS</h2>
                    <p className='flex items-start justify-start space-x-8'>
                        <p className=''>
                            Some feature of the Service may require your mobile device to have access to the internet and may be
                            subject to restrictions imposed by your network or internet provider. The Service may require access
                            through your data network which may result in additional charges depending on your payment plan. In
                            addition, your enjoyment of some of the features may be affected by the suitability and performance of
                            your device hardware or data access.
                        </p>
                    </p>
                </article>

                <article className='space-y-2'>
                    <h2 className='font-semibold text-xl'>12. INDEMNIFICATION</h2>
                    <p className='flex items-start justify-start space-x-8'>
                        <p className=''>
                            If you use the Service as part of your business or profession, you agree to indemnify, defend and hold
                            harmless the Company, as well as our respective officers, directors, employees, successors, agents and
                            affiliates, from any and all claims, damages, losses and causes of action (including attorneys’ fees and
                            court costs) arising out of or relating to your breach of this Agreement, your unlawful use of the
                            Service or your violation of the rights of any third party. You agree to cooperate as fully as reasonably
                            required in our defence and/or settlement of any claim. We reserve the right, in our reasonable
                            discretion, to assume exclusive control over the defence and settlement of any matter subject to
                            indemnification by you.
                        </p>
                    </p>
                </article>

                <article className='space-y-2'>
                    <h2 className='font-semibold text-xl'>13. WARRANTY DISCLAIMER</h2>
                    <p className='flex items-start justify-start space-x-8'>
                        <p className=''>
                            THE SERVICE AND CONTENT ARE PROVIDED “AS-IS” AND ON AN “AS AVAILABLE
                            BASIS” WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING,
                            WITHOUT LIMITATION, WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A
                            PARTICULAR PURPOSE OR NON-INFRINGEMENT FROM THE COMPANY. WE MAKE
                            NO WARRANTY AS TO THE QUALITY, ACCURACY, CURRENTNESS, COMPLETENESS,
                            RELIABILITY OR VALIDITY OF THE SERVICE OR CONTENT INCLUDING, WITHOUT
                            LIMITATION, OPINION, STATEMENT, RECOMMENDATIONS, REVIEWS OR OTHER
                            INFORMATION DISPLAYED, UPLOADED OR DISTRIBUTED IN CONNECTION WITH
                            ANY SERVICE. THE COMPANY DOES NOT WARRANT THAT THE FUNCTIONALITY OF
                            THE APPLICATION/SERVICE WILL BE UNINTERRUPTED OR ERROR-FREE, THAT
                            DEFECTS WILL BE CORRECTED, OR THAT THEY WILL BE FREE OF VIRUSES OR
                            OTHER HARMFUL COMPONENTS. FURTHER, THE COMPANY DOES NOT WARRANT,
                            GUARANTEE OR MAKE ANY REPRESENTATIONS REGARDING THE QUALITY OR
                            ACCURACY OF ADVERTISEMENTS FOR ANY PRODUCTS OR SERVICES OFFERED OR
                            PROVIDED BY ITS AFFILIATE OR AGENTS IN CONJUNCTION WITH THE SERVICE.
                        </p>
                    </p>
                </article>

                <article className='space-y-2'>
                    <h2 className='font-semibold text-xl'>14. LIMITATION OF LIABILITY</h2>
                    <p className='flex items-start justify-start space-x-8'>
                        <span className='font-medium'>14.1</span> 
                        <p className=''>
                            THE COMPANY AND YOU DO NOT LIMIT OR EXCLUDE LIABILITY FOR ANY TYPE OF
                            LOSS OR DAMAGE FOR WHICH LIABILITY CANNOT, UNDER APPLICABLE LAW, BE
                            EXCLUDED OR LIMITED, TO THE EXTENT THAT A COURT OF COMPETENT
                            JURISDICTION JUDGES, SUCH THAT SUCH AN EXCLUSION OR LIMITATION WOULD
                            BE INEFFECTIVE UNDER APPLICABLE LAW.
                        </p>
                    </p>

                    <p className='flex items-start justify-start space-x-8'>
                        <span className='font-medium'>14.2</span> 
                        <p className=''>
                            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL
                            THE COMPANY BE LIABLE FOR ANY SPECIAL, INCIDENTAL, CONSEQUENTIAL,
                            STATUTORY, EXEMPLARY, PUNITIVE OR OTHER INDIRECT DAMAGES OR FOR ANY
                            LOSS OF PROFITS, LOSS OF DATA, LOSS OF BUSINESS, LOSS OF OPPORTUNITY OR
                            LOSS OF USE DAMAGES (INCLUDING NEGLIGENCE), LAWS ON THIRD PARTY
                            OBLIGATIONS (INCLUDING ‘TORT’ AND NEGLIGENCE CLAIMS) EVEN IF IT HAS
                            BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                        </p>
                    </p>

                    <p className='flex items-start justify-start space-x-8'>
                        <span className='font-medium'>14.3</span> 
                        <p className=''>
                            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, YOU AGREE THAT
                            THE COMPANY’S MAXIMUM AGGREGATE FINANCIAL LIABILITY ARISING OUT OF
                            THIS AGREEMENT WILL NOT EXCEED THE AMOUNT YOU PAID TO THE COMPANY
                            OR AGENTS SPECIFICALLY FOR THIS SERVICE. THIS LIMITATION SHALL APPLY TO
                            ANY AND ALL LIABILITIES OR CAUSES OF ACTION HOWEVER ALLEGED OR ARISING,
                            INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE, BREACH OF CONTRACT, OR ANY
                            OTHER CLAIM WHETHER IN TORT, CONTRACT, OR EQUITY.
                        </p>
                    </p>
                </article>

                <article className='space-y-2'>
                    <h2 className='font-semibold text-xl'>15. TERMINATION OR SUSPENSION</h2>
                    <p className='flex items-start justify-start space-x-8'>
                        <span className='font-medium'>15.1</span> 
                        <p className=''>
                            This Agreement becomes effective once accepted by you and will remain in effect until terminated.
                            You may terminate this Agreement anytime by uninstalling, ceasing use, deleting and, if possible
                            returning all software, documentations and other related materials provided by the Company. Any
                            violation of this Agreement or the rules and conditions of the Service may result in the termination of
                            your Account without prior notice from the Company. You agree that the Company will not be liable
                            to you or to any third party for any modification, suspension, or termination of the Service or your
                            access to the Application.
                        </p>
                    </p>
                </article>

                <article className='space-y-2'>
                    <h2 className='font-semibold text-xl'>16. GENERAL PROVISIONS</h2>
                    <p className='flex items-start justify-start space-x-8'>
                        <span className='font-medium'>16.1</span> 
                        <p className=''>
                            <b>Entire Agreement.  </b> 
                            These Terms and Conditions constitute the entire agreement between you
                            and the Company and govern your use of the Service and supersede any prior agreements
                            between you and the Company with respect to the Service (including, without limitation, earlier
                            versions of this Agreement that may have been accepted by you). Any representations,
                            statements or agreements made or entered into elsewhere, whether directly or indirectly, written
                            or oral or in advertising are not binding toward the Company unless expressly confirmed in
                            writing by the Company to you. You may also be subject to additional terms and conditions that
                            may apply when you use or purchase certain other services, affiliate services, third party content
                            or third party software.
                        </p>
                    </p>

                    <p className='flex items-start justify-start space-x-8'>
                        <span className='font-medium'>16.2</span> 
                        <p className=''>
                            <b>Governing Law.  </b> 
                            The validity, construction and interpretation of this Agreement and the
                            relationship between You and the Company, including the rights and duties of the parties, will be
                            governed by the laws of the Republic of Nigeria without regard to its conflict of law provisions.
                            This shall not limit the protection afforded to you by provisions that cannot be derogated from
                            by agreement by virtue of applicable law.
                        </p>
                    </p>

                    <p className='flex items-start justify-start space-x-8'>
                        <span className='font-medium'>16.3</span> 
                        <p className=''>
                            <b>Interpretation.  </b> 
                            Headings under this Agreement are intended only for convenience and shall not
                            affect the interpretation of this Agreement.
                        </p>
                    </p>

                    <p className='flex items-start justify-start space-x-8'>
                        <span className='font-medium'>16.4</span> 
                        <p className=''>
                            <b>Waiver and Severability of Terms.  </b> 
                            Any waiver or failure to enforce any provision of this
                            Agreement on one occasion will not be deemed a waiver of any other provision or of such
                            provision on any other occasion. If any provision of this Agreement is held to be invalid, such
                            invalidity shall not affect the remaining provisions, except as otherwise stated.
                        </p>
                    </p>

                    <p className='flex items-start justify-start space-x-8'>
                        <span className='font-medium'>16.5</span> 
                        <p className=''>
                            <b>Assignment.  </b> 
                            You may not assign, transfer, or otherwise dispose of your rights and obligations
                            under this Agreement, in whole or in part, without our prior written consent, and any such
                            assignment without such consent will be null and void. The Company has the right to transfer,
                            assign or otherwise dispose of these Terms and Conditions without your consent.
                        </p>
                    </p>

                    <p className='flex items-start justify-start space-x-8'>
                        <span className='font-medium'>8.1</span> 
                        <p className=''>
                            <b>Survival.  </b> 
                            Clause 5, 12, 13, 14 and 16.2 of this Agreement and any other provision that by its
                            nature shall continue to be in effect, and shall survive the expiration or earlier termination of this
                            Agreement, and remain valid and binding.
                        </p>
                    </p>
                </article>
            </div>
        </div>
    </div>
  )
}

export default Terms