import React, { useContext, useState } from 'react'
import { CountrySelectorDropdown, FlagEmoji } from 'react-international-phone'
import { MultiStepContext } from '../../context/multistepContext'
import { Input } from './input'

export const CustomPhoneInput = ({ enteredTel, telChangeHandler, telBlurHandler, telError, phone, setPhone }) => {

    const { formState, setFormState } = useContext(MultiStepContext)

    const [country, setCountry] = useState(formState?.countryFlag ?? 'ng')
    const [show, setShow] = useState(false)

    const handleSelectCountry = (item) => {
        setCountry(() => item?.iso2)
        setPhone(() => item?.dialCode)
        setFormState({
            ...formState,
            countryFlag: item?.iso2
        })
        setShow(prev => !prev)
        console.log(item)
    }

    const handleSelectDropdown = () => {
        setShow(prev => !prev);
    }

  return (
    <div className='flex items-end justify-center space-x-2 w-full'>
        <div onClick={handleSelectDropdown} className='relative cursor-pointer flex items-center justify-center space-x-2 py-2.5 pl-3.5 pr-5 rounded-xl border border-tly-stroke'>
            <FlagEmoji iso2={country} size="20px" />
            <p className='text-xs text-tly-gray'>+{phone}</p>
            <CountrySelectorDropdown
                style={{
                    position: 'absolute',
                    zIndex: 999,
                    paddingTop: "10px"

                }}
                show={show}
                selectedCountry={country}
                onSelect={handleSelectCountry}
                onClose={handleSelectDropdown}
            />
        </div>

        <Input 
            wrapperClassName = 'w-full'
            name="phone"
            // label="Phone Number"
            type="tel"
            placeholder={(formState?.phone && formState?.phone.slice(0, 5)) ?? "Enter your phone number"}
            value={enteredTel}
            onChange = {telChangeHandler}
            onBlur={telBlurHandler}
            // required
            error={telError}
            // errorText={telError ? "Enter a Valid Telephone Number" : null}
        />
    </div>
  )
}