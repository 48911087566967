import './index.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import ErrorPage from "./components/ErrorPage/errorpage"
import { createBrowserRouter, redirect, RouterProvider } from "react-router-dom"
import { WebForm } from './Routes/webform';
import { PersonalInfo } from './Routes/registeration/personalInfo';
import { CreatePassword } from './Routes/registeration/createPassword';
import { ProfessionalInfo } from './Routes/registeration/professionalInfo';
import { Referrer } from './Routes/registeration/referrer';
import { Verify } from './Routes/verify/verify';
import { Layout } from './Routes/landing/layout/Layout'
import 'rsuite/dist/rsuite-no-reset.min.css';
import 'react-international-phone/style.css';
import Privacy from './Routes/policy/privacy';
import Terms from './Routes/policy/terms';
import { CreateNewPassword } from './Routes/auth/forgotpassword';
import { Navigate } from "react-router-dom";


const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        index: true,
        element: <App />
      }
    ]
  },
  {
    path: '/privacy-policy',
    element: <Privacy />,
  },
  {
    path: '/terms&conditions',
    element: <Terms />,
  },
  {
    path: "/reg",
    element: <WebForm />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        loader: () => redirect('personal-info')
      },
      {
        path: "personal-info",
        element: <PersonalInfo />
      },
      {
        path: "create-password",
        element: <CreatePassword />
      },
      {
        path: "professional-info",
        element: <ProfessionalInfo />
      },
      {
        path: "referrer-details",
        element: <Referrer />
      },
    ]
  },
  {
    path: '/doctor/verify/:verifyId',
    element: <Verify />
  },
  {
    path: '/doctor/reset-password/:doctorId',
    element: <CreateNewPassword />
  },

 // Catch-all route
  {
    path: "*",
    element: <Navigate to="/" replace />,
  },
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
