import { createContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Storage } from "../App/storage"

export const MultiStepContext = createContext()
export const FORMSTATE = 'FORMSTATE'
export const STEPS = 'STEPS'

export const MultiStepContextProvider = ({ children }) => {
    const { pathname } = useLocation()
    const activePath = pathname.split('/').splice(-1)[0]
    const [formState, setFormState] = useState(Storage.getItem(FORMSTATE) ?? {})

    const Data = [
        {
            currentStep: "personal-info"
        }
    ]
    const navigate = useNavigate()
    const [currentStep, setCurrentStep] = useState(Data[0]?.currentStep ?? "personal-info")

    const [steps, setSteps] = useState(Storage.getItem(STEPS) ?? {
        info: {
            id: 0,
            name: "Personal Info",
            path: "personal-info",
            complete: false
        },
        password: {
            id: 1,
            name: "Create password",
            path: "create-password",
            complete: false
        },
        proInfo: {
            id: 2,
            name: "Professional info",
            path: "professional-info",
            complete: false
        },
        referrer: {
            id: 3,
            name: "Referrer details",
            path: "referrer-details",
            complete: false
        }
    })

    useEffect(() => {
        setCurrentStep(activePath)
    }, [pathname])

    const checkSingleStep = Object.values(steps).map((item) => {
        return item
    });
    const lastStep = checkSingleStep[Object.entries(steps).length - 1]
    const getNextPath = Object.values(steps).map(item => {
        if(item.path === currentStep && activePath){
            return checkSingleStep[item.id + 1]?.path
        }
        return null
    })

    const handleNextStep = () => {
        if(currentStep === lastStep.path)
            return;
        setCurrentStep(getNextPath.filter(Boolean).join(', '))
        navigate(getNextPath.filter(Boolean).join(', '), { state: formState })
    }

    const setNewStep = (key, formValues) => {
        const updatedSteps = {
            ...steps,
            [key]: { ...steps[key], complete: true }
        }
        const updatedState = {
            ...formState,
            ...formValues
        }
        setFormState(() => {
            return updatedState
        })

        setSteps(() => {
            return updatedSteps
        })

        Storage.setItem(STEPS, updatedSteps)
        Storage.setItem(FORMSTATE, updatedState)
        handleNextStep();
    }

    return (
        <MultiStepContext.Provider value={{ 
            steps, 
            setNewStep, 
            currentStep, 
            setCurrentStep, 
            lastStep,
            formState,
            setFormState,
        }}>
            {children}
        </MultiStepContext.Provider>
    )
}