import React from 'react'
import { CallingIcon, LocationIcon, MailBoxIcon } from '../../../Assets/Icons/generated'
import InstagramIcon from '../../../Assets/Icons/Instagram.svg'
import FaceBook from '../../../Assets/Icons/facebook.svg'
import Twitter from '../../../Assets/Icons/twitter.svg'
import Linkedin from '../../../Assets/Icons/linkedin.svg'
import Tiktok from '../../../Assets/Icons/tiktok.svg'
import { Link } from 'react-router-dom'

export const Footer = () => {
    
  return (
    <div id='footer' className='w-full pt-8 px-8 md:px-14 flex items-center justify-center bg-tly-stroke'>
        <div className='w-full max-w-screen-2xl'>
            <div className='grid grid-cols-1 gap-12 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 p-4'>
                <section className='flex items-start justify-start flex-col space-y-5'>
                    <h1 className='text-lg font-semibold'>About Us</h1>
                    <main className='flex items-start justify-start flex-col'>
                        <h2 className='font-bold flex items-center'>Tendarly</h2>
                        <p className='text-base'>
                            Tendarly is a product of Heckerbella Limited.
                            Our vision is to make it simple to access quality healthcare.
                        </p>
                    </main>
                </section>

                <section className='flex items-start justify-start flex-col space-y-5'>
                    <h1 className='text-lg font-semibold'>Contact Us</h1>
                    <main className='flex items-start justify-start flex-col'>
                        <div className='flex items-start justify-start space-x-4'>
                            <MailBoxIcon />
                            <div className='flex items-start flex-col'>
                                <h2 className='font-bold flex items-center'>Email</h2>
                                <p className='text-base'>tendarlysupport@heckerbella.com</p>
                            </div>
                        </div>
                    </main>

                    <main className='flex items-start justify-start flex-col'>
                        <div className='flex items-start justify-start space-x-4'>
                            <CallingIcon />
                            <div className='flex items-start flex-col'>
                                <h2 className='font-bold flex items-center'>Phone</h2>
                                <p className='text-base'>+234 706 735 8926</p>
                            </div>
                        </div>
                    </main>

                    <main className='flex items-start justify-start flex-col'>
                        <div className='flex items-start justify-start space-x-4'>
                            <LocationIcon />
                            <div className='flex items-start flex-col'>
                                <h2 className='font-bold flex items-center'>Address</h2>
                                <p className='text-base'>
                                    9th floor inside Ibukun House, 14 Adetokunbo Ademola Street, Victoria Island 106104, Lagos
                                </p>
                            </div>
                        </div>
                    </main>
                </section>

                <section className='flex items-start justify-start flex-col space-y-5'>
                    <h1 className='text-lg font-semibold'>Follow Us On</h1>
                    <main className='flex items-center justify-between gap-6'>
                        <a href='https://www.instagram.com/tendarly_/' rel='noreferrer noopener' target='_blank'>
                            <img className='cursor-pointer' src={InstagramIcon} alt='social-icon' />
                        </a>
                        <img className='cursor-pointer' src={FaceBook} alt='social-icon' />
                        <a href='https://twitter.com/Tendarly_' rel='noopener noreferrer' target='_blank'>
                            <img className='cursor-pointer' src={Twitter} alt='social-icon' />
                        </a>
                        <a href='https://www.youtube.com/channel/UCBuuQ8yCRMgqJ_Yr9CL2E5Q' rel='noopener noreferrer' target='_blank'>

                        </a>
                        {/* <img className='cursor-pointer' src={Linkedin} alt='social-icon' /> */}
                        {/* <img className='cursor-pointer' src={Tiktok} alt='social-icon' /> */}
                    </main>
                </section>
            </div>

            <footer className='text-tly-gray flex items-center justify-between border-t border-tly-gray p-4 w-full'>
                <div className='flex items-center gap-8'>
                    <Link to="/privacy-policy" target="_blank" rel="noopener noreferrer">
                        <p>Privacy Policy</p>
                    </Link>
                    <Link to="/terms&conditions" target="_blank" rel="noopener noreferrer">
                        <p>Terms & Conditions</p>
                    </Link>
                </div>
                <p>All Right Reserved! © 1999 - 2023 - Heckerbella Limited</p>
            </footer>
        </div>
    </div>
  )
}