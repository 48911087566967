import moment from 'moment'
import React, { useContext, useState } from 'react'
import { DatePicker } from 'rsuite'
import { Button } from '../../components/forms/button'
import { DropDownSelect } from '../../components/forms/dropdown'
import { Input } from '../../components/forms/input'
import { MultiStepContext } from '../../context/multistepContext'
import { useForm } from "../../hooks/useForm"
import { isValidEmail, isValidName, isValidTel } from '../../utils/validation'
import { CustomPhoneInput } from '../../components/forms/phoneInput'
import { Toaster, toast } from 'sonner';
import axios from 'axios'
import { AvatarIcon, GalleryIcon } from '../../Assets/Icons/generated'
import Fallback from '../../Assets/Icons/Avatars.svg'
import { FormHeader } from '../../components/global/formheader'

const genders = [
  {
    id: 1,
    name: "Male",
    value: "Male"
  },
  {
    id: 2,
    name: "Female",
    value: "Female"
  },
  {
    id: 3,
    name: "Other",
    value: "Others"
  },
  {
    id: 4,
    name: "Choose not to specify",
    value: "Choose not to specify "
  },
]

export const PersonalInfo = () => {

  const { setNewStep, formState, setFormState } = useContext(MultiStepContext)

  const [date, setDate] = useState(new Date(formState?.dateOfBirth ?? null));
  const [isLoading, setIsLoading] = useState(false)
  const [selectedFile, setSelectedFile] = useState(formState?.profile_picWeb)
  const [selectedImageFile, setSelectedImageFile] = useState(formState?.profile_pic)
  
  const {
    value: enteredFirstName,
    hasError: firstNameHasError,
    isValid: firstNameIsValid,
    valueChangeHandler: firstNameChangeHandler,
    inputBlurHandler: firstNameBlurHandler,
    reset: resetFirstName,
  } = useForm(isValidName, formState?.firstname)
  
  const {
    value: enteredLastName,
    hasError: lastNameHasError,
    isValid: lastNameIsValid,
    valueChangeHandler: lastNameChangeHandler,
    inputBlurHandler: lastNameBlurHandler,
    reset: resetLastName,
  } = useForm(isValidName, formState?.lastname)
  
  const {
    value: enteredEmail,
    hasError: emailError,
    isValid: emailIsValid,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset: resetEmail
  } = useForm(isValidEmail, formState?.email)
  
  const {
    value: enteredTel,
    hasError: telError,
    isValid: telIsValid,
    valueChangeHandler: telChangeHandler,
    inputBlurHandler: telBlurHandler,
    reset: resetTel
  } = useForm(isValidTel, formState?.phone && formState?.phone.slice(5))
  
  const [phone, setPhone] = useState((formState?.phone && formState?.phone.slice(1, 4)) ?? '234')
  const Telephone = `+${phone} ${enteredTel}`

  const handleNextStep = async (e) => {
    e.preventDefault();
    if(!selectedFile){
      toast.error("Please provide a profile Image")
      return;
    }
    setIsLoading(true)
    const info = {
      profile_pic: selectedImageFile,
      firstname: enteredFirstName,
      lastname :enteredLastName,
      email: enteredEmail,
      phone: Telephone,
      dateOfBirth: moment(date).format("YYYY-MM-DD"),
    }
    try {
      const res = await axios.post(`https://api.tendarly.com/api/core/auth/user/verify-credential/`, {
        email: enteredEmail,
        phone: enteredTel
      })
      setIsLoading(false)
      setNewStep("info", info)
    } catch (error) {
      toast.error(error?.response?.data?.message)
      setIsLoading(false)
    }
    setIsLoading(false)
  }

  const handleFileUpload = (event) => {
    event.preventDefault()
    const { files } = event.target;
    if(!files[0]) return;
    setFormState({
      ...formState,
      profile_pic: files[0],
      profile_picWeb: URL.createObjectURL(files[0])
    })
    setSelectedFile(() => URL.createObjectURL(files[0]))
    setSelectedImageFile(() => files[0])
  }

  const handleSelect = (options) => {
    setFormState({
      ...formState,
      gender: options.value
    });
  }

  let isValid = false;
  if(
      firstNameIsValid && lastNameIsValid && emailIsValid 
      && telIsValid && (formState?.gender !== "" && formState?.gender !== {} 
      && formState?.gender !== undefined) && (date !== "" && date !== undefined 
      && date !== null)
    ) {
    isValid = true;
  }

  return (
    <div className='w-full flex justify-center flex-col gap-12'>
      <FormHeader
        heading="Let's Get You Started"
        subHead="Let's get to know you better. Please fill in the following details to enhance your Tendarly experience."
      />
      <form onSubmit={handleNextStep} className='w-full flex items-center justify-center flex-col space-y-8'>
        <section className="flex items-center justify-center flex-col gap-2">
          <label className='w-fit cursor-pointer rounded-full inline bg-tly-gray3'>
            <input 
                className='hidden'
                type='file'
                name="img"
                id="img"
                accept='image/*'
                onChange={handleFileUpload}
            />
            <img
                className="w-40 h-40 rounded-full"
                style={{
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    objectFit: "cover",
                }}
                src={(formState?.profile_picWeb) ?? selectedFile ?? Fallback}
                onError={(e) => {
                  e.target.onerror = Fallback
                  e.target.src =  Fallback
                }}
            />
          </label>
          <span className='flex text-sm text-tly-gray4'>{ selectedFile ? 'Change profile Picture' : 'Add a profile Picture'}<GalleryIcon style={{paddingLeft: '2px'}} /></span>
        </section>
        <section className='w-full grid grid-cols-1 gap-4'>
          <Input 
            name="firstname"
            label="First Name"
            type="text"
            placeholder={formState?.firstname ?? "Enter your first name"}
            onChange = {firstNameChangeHandler}
            value={enteredFirstName}
            onBlur={firstNameBlurHandler}
            errorText = { firstNameHasError ? "Enter a Valid first name" : null }
            error={firstNameHasError}
            required
          />
          <Input 
            name="lastname"
            label="Last Name"
            type="text"
            placeholder={formState?.lastname ?? "Enter your last name"}
            value={enteredLastName}
            onChange = {lastNameChangeHandler}
            onBlur={lastNameBlurHandler}
            error={lastNameHasError}
            errorText={ lastNameHasError ? "Enter a valid last name" : null }
            required
          />
        </section>
        <section className='w-full grid grid-cols-1 gap-4'>
          <Input 
            type="email"
            name="email"
            label= "Email"
            placeholder ={formState?.email ?? "Enter your email..."}
            value={enteredEmail}
            onChange={emailChangeHandler}
            onBlur = {emailBlurHandler}
            required
            error = {emailError}
            errorText=  {emailError ? "Enter a valid email" : null}
          />
          <div className='flex items-start flex-col w-full'>
            <label>Phone Number</label>
            <CustomPhoneInput 
              phone={phone}
              setPhone={setPhone}
              enteredTel={enteredTel}
              telBlurHandler={telBlurHandler}
              telChangeHandler={telChangeHandler}
              telError={telError}
            />
          </div>
        </section>

        <section className='w-full grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2'>
          <div className='flex items-start flex-col space-y-1'>
            <label>Date of Birth</label>
            <DatePicker 
              size='lg'
              label="Select Date"
              placeholder="Date" 
              value={date} 
              onChange={setDate}
              placement="topStart"
              style={{ width: "100%", cursor: "pointer", padding: "0px" }} 
              format="yyyy-MM-dd'"
            />
          </div>

          <DropDownSelect
            options={genders}
            defaultValue={formState?.gender ?? "Select your gender"}
            onChange={handleSelect}
            label="Gender"
            name='gender'
        />
        </section>

        <div className='pt-8 w-full'>
          <Button 
            children="Continue"
            fullWidth={true}
            disabled={!isValid}
            variant={!isValid ? 'default' : 'primary'}
            isLoading={isLoading}
          />
        </div>
      </form>
      <Toaster richColors position='top-center' />
    </div>
  )
}