export const TextCheckBox = ({ id, label, node, onChange, checked, ...rest }) => {

    return (
      <label id={id} className='flex items-center gap-3'>
        <input
          {...rest}
          onChange={onChange}
          type="checkbox"
          className="w-5 h-5 border border-tgray-100 cursor-pointer rounded-md text-tly-gray5 bg-tly-gray5
          focus:ring-tly-landmain dark:focus:ring-tly-landmain dark:ring-offset-tly-landmain
          dark:border-text-tly-gray5"
          checked={checked}
        />
        {label ? <span className='cursor-pointer text-base text-tly-gray5'>{label}</span> : null}
        {node ? node : null}
      </label>
    )
  }