import React, { useContext, useState } from 'react'
import { Button } from '../../components/forms/button'
import { Input } from '../../components/forms/input'
import { useForm } from '../../hooks/useForm'
import { confirmPasswordMatches, isNotEmpty, isValidPassword, passwordMatcher } from '../../utils/validation'
import Logo from '../../Assets/Icons/Logo.png'
import * as Icon from 'react-feather'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { HeartLoadIcon } from '../../Assets/Icons/generated'
import { Modal } from '../../components/global/modal'

export const CreateNewPassword = () => {

    const { doctorId } = useParams();

    const [showPassword, setShowPassword] = useState(false)
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('')
    const [status, setStatus] = useState("")
    const [touched, setTouched] = useState(false)
    
    const {
        value: enteredPassword,
        hasError: passwordHasError,
        isValid: passwordIsValid,
        valueChangeHandler: passwordChangeHandler,
        inputBlurHandler: passwordBlurHandler,
        reset: resetPassword,
    } = useForm(isValidPassword)

    const {
        value: enteredConfirmPassword,
        hasError: confirmPasswordHasError,
        isValid: confirmPasswordIsValid,
        valueChangeHandler: confirmPasswordChangeHandler,
        inputBlurHandler: confirmPasswordBlurHandler,
        isTouched: confirmIsTouched,
        reset: resetConfirmPassword,
    } = useForm(confirmPasswordMatches(enteredPassword))

    let isValid = passwordMatcher(enteredPassword, enteredConfirmPassword)

    const togglePasswordVisibility = () => {
        setShowPassword(prev => !prev)
    }
    const toggleModal = () => {
        setShowModal(prev => !prev)
    }
    
    const handleChangePassword = async (event) => {
        event.preventDefault();
        setIsLoading(true)
        const data = {
            code: doctorId ?? "",
            password: enteredPassword
        }
        try {
            const response = await axios.post(`https://api.tendarly.com/api/doctor/auth/verify/`, data);
            if(response.status === 200)
            setStatus("success")
        } catch (error) {
            if(error?.response?.data?.message === "expired")
            setStatus("expired")
            if(error?.response?.data?.message === "already verified")
            setStatus("verified")
        } finally{
            setShowModal(true)
            setIsLoading(false)
        }
    }

    return (
        <div className='w-full h-[100dvh] flex items-center justify-center flex-col p-4 md:p-20 bg-tly-gray3'>
            <main className='w-full md:w-2/4 flex items-center justify-center flex-col space-y-8 bg-white p-10 rounded-md'>
                <header className='w-full flex items-center justify-center flex-col space-y-8'>
                    <div className='flex items-center space-x-1'>
                        <img className='w-10 h-10' src={Logo} alt='logo' />
                        <p className='font-bold text-2xl'>Tendarly</p>
                    </div>
                    <div className='flex items-center flex-col p-2 space-y-2'>
                        <header className='text-2xl font-semibold'>Create new password</header>
                        <p className='text-center text-base text-tly-gray font-normal'>
                            Create a new password for okisgbon@gmail.com.
                        </p>
                    </div>
                </header>
                <form onSubmit={handleChangePassword} className='w-full'>
                    <section className='w-full flex items-start flex-col space-y-4'>
                    <Input
                        wrapperClassName= "w-full relative"
                        name="password"
                        label="Create Password"
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Enter password"
                        value={enteredPassword}
                        onChange={passwordChangeHandler}
                        onBlur={passwordBlurHandler}
                        required
                        error={passwordHasError}
                        eye
                        icon = {
                        showPassword ?  <Icon.EyeOff size={15} onClick={togglePasswordVisibility} /> :  <Icon.Eye size={15} onClick={togglePasswordVisibility} />
                        }
                        errorText= {passwordHasError ? "Password Must be a minimum of 8 characters and contain at least a number, an upperCase character and a special character (!, @, #, $, %, &, *)" : null }
                    />
                    <Input 
                        onFocus={() => {
                        setTimeout(() => {
                            setTouched(true);
                        }, 3000)
                        }}
                            wrapperClassName= "w-full"
                            name="password"
                            label="Confirm Password"
                            type="password"
                            placeholder="confirm password"
                            value={enteredConfirmPassword}
                            onChange={confirmPasswordChangeHandler}
                            onBlur={confirmPasswordBlurHandler}
                            required
                            error={touched && !confirmPasswordIsValid}
                            errorText={touched && !confirmPasswordIsValid ? "Passwords do not match" : null}
                    />
                    </section>

                    <div className='pt-8 w-full'>
                    <Button
                        children="Save"
                        fullWidth={true}
                        disabled={ !isValid}
                        variant={!isValid ? "default" : "primary"}
                        isLoading={isLoading}
                    />
                    </div>
                </form>
            </main>
            <Modal
                show={showModal}
                contentBgColor='bg-tly-white'
                contentWidth='w-full lg:w-2/3'
                position="center"
                padding="4"
                onClose={toggleModal}
                // showCloseIcon
                shouldCloseOnEscPress={false}
                shouldCloseOnOverlayClick={false}
            >
                {
                    isLoading ?
                    <LoadingModal />
                    : status === "success" ?
                    <VerifyModal />
                    : status === "expired" ?
                    <ErrorModal />
                    : status === "verified" ?
                    <VerifiedModal /> :
                null
                }
            </Modal>
        </div>
    )
}


export const VerifyModal = () => {
    return (
        <div className='flex items-start flex-col space-y-6'>
            <header className='border-b border-tly-stroke w-full'>
                <h1 className='font-semibold text-2xl'>Password Successfully Reset!</h1>
            </header>
            <main>
                <p>
                    Great news! Your password has been successfully reset. 
                    You can now head back to the app and sign in with your newly created password.
                </p>
            </main>
        </div>
    )
}

export const VerifiedModal = () => {
    return (
        <div className='flex items-start flex-col space-y-6'>
            <header className='border-b border-tly-stroke w-full'>
                <h1 className='font-semibold text-2xl'>This Email is Already Verified!</h1>
            </header>
            <main>
                <p>
                    This Email has been verified already!. 
                    Please return to the Tendarly app and log in to access your account and start your journey with us.
                </p>
            </main>
        </div>
    )
}

export const LoadingModal = () => {
    return (
        <div className='flex items-center justify-center flex-col w-full'>
            <HeartLoadIcon />
            <p className='text-2xl font-semibold'>Loading...</p>
        </div>
    )
}



export const ErrorModal = () => {

    const navigate = useNavigate();

    const handleRegisterRouting = () => {
        navigate('/', { replace: true })
    }

    return(
        <div className='flex items-start flex-col space-y-6'>
            <header className='border-b border-tly-stroke w-full flex items-center space-x-3'>
                <Icon.AlertCircle color='#CB1B10' />
                <h1 className='font-semibold text-2xl text-tly-error'>Password Reset Failed!</h1>
            </header>
            <main>
                <p className='text-tly-error'>
                    We're sorry, but this link has expired. Please tap <span className='text-tly-main italic underline cursor-pointer' onClick={handleRegisterRouting}>here</span> to get a new link. 
                </p>
            </main>
        </div>
    )
}