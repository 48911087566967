import classNames from "classnames";
import PropTypes from "prop-types";
import { Loader } from "rsuite";
// import { ColorRing } from 'react-loader-spinner'
// import { useMediaQuery } from "../../Hooks/useMediaQuery";

export const Button = ({ 
  id, 
  color, 
  radius, 
  tColor, 
  icon, 
  loadColor, 
  isLoading,  
  fullWidth, 
  className, 
  children, 
  leftIcon, 
  rightIcon, 
  rounded, 
  variant, 
  onClick, 
  disabled, 
  type,
  ref, 
  ...rest 
  }) => {
  
//   let isMonitor = useMediaQuery("(min-width: 2560px)");

  return (
    <button
      ref={ref}
      type = {type}
      onClick={onClick}
      id={id}
      disabled={disabled}
      className={classNames(
        `md:text-sm text-xs cursor-pointer select-none [outline:none] disabled:cursor-not-allowed
          disabled:bg-neutral-300 disabled:text-neutral-100 disabled:shadow-inner
          ${fullWidth ? "w-full text-center flex items-center justify-center" : "flex items-center justify-between"}
          transition-all ease-in-out duration-300`,
        rounded ? "rounded" : "",
        variant === "default" 
        ? "bg-tly-mainsoft text-tly-white space-x-2 px-6 py-[14px]" 
        : "",
        variant === "contrast" 
        ? "bg-tly-none text-tly-white border-[1px] border-tly-white space-x-2 px-6 py-[14px] " 
        : "",
        variant === "primary"
          ? " space-x-2 px-6 py-[14px] bg-tly-landmain focus:ring-blue-300 text-tly-white hover:bg-tly-hovermain hover:text-tly-white focus:bg-tly-focusmain focus:ring-tly-focusmain"
          : "",
        variant === "link"
          ? "bg-none text-tly-main border-none px-0 space-x-2"
          : "",
        variant === "success"
          ? "bg-tly-success text-tly-white space-x-2 px-6 py-[14px]"
          : "",
        variant === "error"
          ? "bg-tly-error text-tly-white space-x-2 px-6 py-[14px]"
          : "",
        variant === "primary-outline"
          ? "text-tly-main border-[1px] border-tly-main hover:bg-tly-main hover:text-tly-white focus:ring-tly-main space-x-2 px-6 py-[14px]"
          : "",
        variant === "success-outline"
          ? "text-tly-white border-[1px] border-green-700 hover:bg-green-700 hover:text-white focus:ring-green-500 space-x-2 px-6 py-[14px]"
          : "",
        variant === "error-outline"
          ? "text-tly-error border-[1px] border-tly-error hover:bg-tly-error hover:text-tly-white focus:ring-tly-error space-x-2 px-6 py-[14px]"
          : "",
          variant === "error-link"
          ? "bg-none text-tly-error border-none px-0 space-x-2"
          : "",
          variant === "icon"
          ? `p-1 ${color ? color : 'bg-tly-bgg'} ${radius} text-tly-${tColor ? tColor : "black"} flex items-center justify-center space-y-0 space-x-0 m-0`
          : "",
        className
      )}
      {...rest}
    >
      {!!leftIcon && leftIcon}
      {isLoading && 
        // <ColorRing
        //   colors={["#FFF", "#FFF", "#FFF", "#FFF", "#FFF"]}
        //   ariaLabel="blocks-loading"
        //   animationDuration="0.75"
        //   width={isMonitor ? 40 : 20}
        //   height={isMonitor ? 40 : 20}
        //   wrapperClass="blocks-wrapper"
        //   visible={true}
        // />
        <Loader size="xs" />
      }
      {children ? <span className="whitespace-nowrap">{children}</span> : null}
      {icon ? <span>{icon}</span> : null}
      {!!rightIcon && rightIcon}
    </button>
  );
}

Button.defaultProps = {
  rounded: true,
  variant: "primary",
};

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  rounded: PropTypes.bool.isRequired,
  variant: PropTypes.oneOf([
    "primary",
    "secondary",
    "success",
    "error",
    "default",
    "contrast",
    "link",
    "error-link",
    "icon",
    "primary-outline",
    "link-outline",
    "success-outline",
    "error-outline",
  ]),
};