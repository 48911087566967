import React from 'react'
import AppStore from '../../../Assets/Images/appstore.png'
import PlayStore from '../../../Assets/Images/googleplay.png'
import ReactGA from 'react-ga'

export const HeroPage = () => {
  ReactGA.initialize('G-QSGJQXDNNF', { debug: true });
  ReactGA.pageview(window.location.pathname + window.location.search);
  ReactGA.event({
      category: 'Button',
      action: 'Click',
      label: 'Join as a Doctor'
  })  
  return (
    <div id='hero' className='w-full px-7 md:px-14 py-12 flex items-center justify-center flex-col space-y-12'>
      <div className='font-medium flex items-center justify-between w-full lg:w-1/3 text-center text-sm md:text-base'>
        <span>AI Assistant </span>
        <span>Virtual Consultation</span>
        <span>Medical record</span>
      </div>
      <section className='flex items-center flex-col space-y-6'>
        <p className='text-3xl md:text-7xl font-bold text-center'>Simple access to doctors</p>
        <span className='text-lg text-center'>We've simplified the path to expert care, so you can experience healthcare at its best.</span>
      </section>
      <section className='flex items-center justify-center flex-col space-y-4'>
        <span className='text-base text-tly-gray'>Download the Doctors app</span>
        <div className='flex items-center space-x-4'>
          <a href='https://apps.apple.com/in/app/tendarly-doctor/id6470204751' rel='noopener noreferrer' target='_blank'>
            <img className='w-28 md:w-full h-10' src={AppStore} alt='app-store-download' />
          </a>
          <a href = 'https://play.google.com/store/apps/details?id=com.tendarly.tendarly_doctor' rel='noopener noreferrer' target='_blank'>
            <img className='w-28 md:w-full h-10' src={PlayStore} alt='google-play-download' />
          </a>
        </div>
      </section>
    </div>
  )
}
