import React from 'react'

export const EmptyState = ({iconNode, icon, text, subtext, node, color}) => {
  return (
    <div className='w-full flex items-center justify-center flex-col space-y-2 h-full'>
      {
        iconNode ?
        iconNode : null 
      }
      { icon ? 
        <img
        className=""
        src={icon}
        style={{
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          objectFit: "cover",
        }}
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = '';
        }}
        alt="profile"
      /> : null
      }
      <p className={`${color ? color : "text-doc-black"} text-center text-xl font-extrabold`}>{text}</p>
      <span className={`${color ? color : "text-doc-gray1"} w-2/3 text-sm text-center`}>{subtext}</span>
      {!!node && node}
    </div>
  )
}