import React from 'react'
import { useNavigate } from 'react-router-dom'
import * as Icon from 'react-feather'

export const FormHeader = ({ heading, subHead, previous }) => {

  const navigate = useNavigate()

  return (
    <header className='flex items-start flex-col gap-2 border-b border-tly-stroke py-4'>
      {
        previous ?
          <div onClick={() => navigate(-1, { replace: true })} className='flex items-center gap-1 cursor-pointer'>
            <Icon.ChevronLeft style={{color: "#999999"}} />
            <span className='text-base text-tly-gray5'>Previous</span>
          </div>
          :
          null
      }
      <h2 className='font-semibold text-2xl text-tly-black'>{heading}</h2>
      <span className='text-base text-tly-gray4'>{subHead}</span>
    </header>
  )
}