import React from 'react'
import AppStore from '../../../Assets/Images/appstore.png'
import PlayStore from '../../../Assets/Images/googleplay.png'
import Iphone from '../../../Assets/Images/exiphone.png'
import Android from '../../../Assets/Images/exandroid.png'

export const Experience = () => {
  return (
    <div className='w-full pt-8 px-8 md:px-14 flex items-center justify-center'>
        <div className='w-full max-w-screen-2xl flex items-center justify-center flex-col space-y-8'>
            <h1 className='font-semibold text-2xl text-[52px] leading-normal'>Experience the healthcare revolution</h1>
            <p>Download the patient app</p>
            <div className='flex items-center space-x-4'>
                <a href='#' rel='noopener noreferrer' target='_blank'>
                    <img className='w-28 md:w-full h-10' src={AppStore} alt='app-store-download' />
                </a>
                <a href='https://play.google.com/store/apps/details?id=com.tendarly.tendarly_patient' rel='noopener noreferrer' target='_blank'>
                    <img className='w-28 md:w-full h-10' src={PlayStore} alt='google-play-download' />
                </a>
            </div>

            <div className='flex flex-col items-center justify-center md:flex-row gap-12'>
                <div>
                    <img className='w-full max-h-[350px]' src={Iphone} alt='iphone-mockup' />
                </div>
                <div>
                    <img className='w-full max-h-[350px]' src={Android} alt='android-mockup' />
                </div>
            </div>
        </div>
    </div>
  )
}
