import React, { useState } from 'react'
import { Modal } from '../../components/global/modal';
import { MultiStepContextProvider } from '../../context/multistepContext';
import { Register } from '../registeration';
import Logo from '../../Assets/Icons/Logo.png'

export const WebForm = () => {

  const [showModal, setShowModal] = useState(true);

  const toggleModal = () => {
    setShowModal(prev => !prev)
  }

  const modalHeader = 
    <header className='flex items-center justify-center flex-col w-full space-y-8'>
      <div className='flex items-center space-x-1'>
        <img className='w-10 h-10' src={Logo} alt='logo' />
        <p className='font-bold text-2xl'>Tendarly</p>
      </div>
  </header>

  return (
    <div className="">
      <MultiStepContextProvider>
        <Modal
          show={showModal}
          contentBgColor='bg-tly-white'
          contentWidth='w-full lg:w-2/3'
          headFragment={modalHeader}
          children={<Register toggleModal={toggleModal} />}
          position="top"
          padding="4"
          onClose={toggleModal}
          // showCloseIcon
          shouldCloseOnEscPress={false}
          shouldCloseOnOverlayClick={false}
        />
      </MultiStepContextProvider>
    </div>
  );
}