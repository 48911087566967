import React, { useEffect, useReducer, useState } from 'react'
import { Modal } from '../../components/global/modal';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { HeartLoadIcon } from '../../Assets/Icons/generated';
import * as Icon from 'react-feather'

export const Verify = () => {

    const { verifyId } = useParams();
    
    const [showModal, setShowModal] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [status, setStatus] = useState("")

    useEffect(() => {
        const abortController = new AbortController();
        const data = {
            code: verifyId ?? "",
        }
        const fetchData = async () => {
            try {
                const response = await axios.post(`https://api.tendarly.com/api/doctor/auth/verify/`, data);
                if(response.status === 200)
                setStatus("success")
            } catch (error) {
                if(error?.response?.data?.message === "expired")
                setStatus("expired")
                if(error?.response?.data?.message === "already verified")
                setStatus("verified")
            } finally{
                setIsLoading(false)
            }
        }

        fetchData();
        return () => abortController.abort();
    }, [])
    

    const toggleModal = () => {
        setShowModal(prev => !prev)
    }

  return (
    <div>
        <Modal
          show={showModal}
          contentBgColor='bg-tly-white'
          contentWidth='w-full lg:w-2/3'
          position="center"
          padding="4"
          onClose={toggleModal}
          // showCloseIcon
          shouldCloseOnEscPress={false}
          shouldCloseOnOverlayClick={false}
        >
            {
                isLoading ?
                <LoadingModal />
                : status === "success" ?
                <VerifyModal />
                : status === "expired" ?
                <ErrorModal />
                : status === "verified" ?
                <VerifiedModal /> :
                null
            }
        </Modal>
    </div>
  )
}

export const VerifyModal = () => {
    return (
        <div className='flex items-start flex-col space-y-6'>
            <header className='border-b border-tly-stroke w-full'>
                <h1 className='font-semibold text-2xl'>Email Verified!</h1>
            </header>
            <main>
                <p>
                    Your account has been successfully verified! Welcome to Tendarly. 
                    Please return to the Tendarly app and log in to access your account and start your journey with us.
                </p>
            </main>
        </div>
    )
}

export const VerifiedModal = () => {
    return (
        <div className='flex items-start flex-col space-y-6'>
            <header className='border-b border-tly-stroke w-full'>
                <h1 className='font-semibold text-2xl'>This Email is Already Verified!</h1>
            </header>
            <main>
                <p>
                    This Email has been verified already!. 
                    Please return to the Tendarly app and log in to access your account and start your journey with us.
                </p>
            </main>
        </div>
    )
}

export const LoadingModal = () => {
    return (
        <div className='flex items-center justify-center flex-col w-full'>
            <HeartLoadIcon />
            <p className='text-2xl font-semibold'>Loading...</p>
        </div>
    )
}

export const ErrorModal = () => {

    const navigate = useNavigate();

    const handleRegisterRouting = () => {
        navigate('/', { replace: true })
    }

    return(
        <div className='flex items-start flex-col space-y-6'>
            <header className='border-b border-tly-stroke w-full flex items-center space-x-3'>
                <Icon.AlertCircle color='#CB1B10' />
                <h1 className='font-semibold text-2xl text-tly-error'>Email Verification Failed!</h1>
            </header>
            <main>
                <p className='text-tly-error'>
                    We're sorry, but this link has expired. Please tap <span className='text-tly-main italic underline cursor-pointer' onClick={handleRegisterRouting}>here</span> to get a new link. 
                </p>
            </main>
        </div>
    )
}