import React from 'react'
import { LineArrow, RadioG } from '../../../Assets/Icons/generated'
import { LandButton } from '../../../components/forms/landButton'
import AppStore from '../../../Assets/Images/appstore.png'
import PlayStore from '../../../Assets/Images/googleplay.png'
import { useNavigate } from 'react-router-dom'
import BG from '../../../Assets/Images/Dots.png'

const usersteps = [
    {
        id: 1,
        node: 
        <div className='flex items-center space-x-4'>
            <a href='#' rel='noopener noreferrer' target='_blank'>
                <img className='w-28 md:w-full h-10' src={AppStore} alt='app-store-download' />
            </a>
            <a href='https://play.google.com/store/apps/details?id=com.tendarly.tendarly_patient' rel='noopener noreferrer' target='_blank'>
                <img className='w-28 md:w-full h-10' src={PlayStore} alt='google-play-download' />
            </a>
        </div>,
        text: `Download the mobile application`,
    },
    {
        id: 2,
        node: '',
        text: `Sign up with your phone number and information`,
    },
    {
        id: 3,
        node: '',
        text: `Start a connection and tell Stacy your symptoms`,
    },
    {
        id: 4,
        node: '',
        text: `Pay for a connection`,
    },
    {
        id: 5,
        node: '',
        text: `Start talking to a doctor`,
        lastStep: true
    },
]


export const HowItWorks = () => {
    
    const navigate = useNavigate()
    const doctorsteps = [
        {
            id: 1,
            node: <LandButton children='Join as a Doctor' variant='primary' onClick={()=>navigate('/reg', {replace: false})} />,
            text: `Join as a Doctor`,
        },
        {
            id: 2,
            node: '',
            text: `Submit your credentials`,
        },
        {
            id: 3,
            node: '',
            text: `Once you’re verified download the mobile application`,
        },
        {
            id: 4,
            node: '',
            text: `Login using your email and password`,
        },
        {
            id: 5,
            node: '',
            text: `Start accepting requests and earning`,
            lastStep: true
        },
    ]

  return (
    <div id='how' 
        style={{
            backgroundImage: `url(${BG})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            objectFit: "contain",
        }}
        className='w-full flex items-center justify-center p-8 py-14 md:px-14 bg-tly-stroke'
    >
        <div className='w-full md:w-4/5 max-w-screen-2xl flex items-center justify-center flex-col space-y-20'>
            <header className='flex items-center justify-center w-full'>
                <p className='text-2xl md:text-[44px] font-semibold'>How it works</p>
            </header>

            <div className='w-full flex flex-col items-center justify-between xl:flex-row space-y-12 xl:space-x-12 xl:space-y-0'>
                <div className='w-full bg-white p-6 rounded-xl shadow-md flex items-center justify-center space-y-12 flex-col'>
                    <h1 className='font-bold text-2xl md:text-4xl'>Users</h1>
                    <div className='w-full flex items-start justify-between flex-col gap-2'>    
                        {
                            usersteps?.map(item => (
                                <Timeline 
                                    key={item.id}
                                    text={item.text}
                                    node={item.node}
                                    lastStep={item.lastStep}
                                />
                            ))
                        }
                    </div>
                </div>

                <div className='w-full bg-white p-6 rounded-xl shadow-md flex items-center justify-center space-y-12 flex-col'>
                    <h1 className='font-bold text-2xl md:text-4xl'>Doctors</h1>
                    <div className='w-full flex items-start justify-between flex-col gap-2'>    
                        {
                            doctorsteps?.map(item => (
                                <Timeline 
                                    key={item.id}
                                    text={item.text}
                                    node={item.node}
                                    lastStep={item.lastStep}
                                />
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export const Timeline = ({ lastStep, step, node, text }) => {

    return (
        <>
            <span key={step} className='flex items-start justify-start grow-0 space-x-2'>
                <div className='flex items-center justify-center flex-col space-y-2'>
                    <RadioG />        
                    <LineArrow className={`
                        ${ lastStep? "hidden" : "block" } 
                        h-12 transition duration-500 ease-in-out`}
                    />
                </div>
                <span className="'w-full flex items-start flex-col text-md md:text-lg whitespace-nowrap p-0 m-0'">
                    {text ? <span className='w-full flex items-start flex-col text-md md:text-lg p-0 m-0 whitespace-normal'>{text}</span> : null } 
                    {node ? node : null}
                </span>
            </span>
        </>
    )
}
