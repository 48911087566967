import React, { useRef, useState } from 'react'
import * as Icon from 'react-feather'
import { useOnOutsideClick } from '../../hooks/useOutsideClick';

export const DropDownSelect = ({defaultValue, options, label, onChange}) => {

  const [show, setShow] = useState(false)
  const [selected, setSelected] = useState('')

  const ref = useRef()

  useOnOutsideClick(ref, () => {
    setShow(false);
  });

  const toggleDropDown = () => {
    setShow(prev => !prev)
  }

  const handleSelected = (option) => {
    onChange(option)
    setSelected(option)
  }

  return (
    <div tabIndex={0} className='space-y-1'>
      {!!label ? (
        <label className="text-sm font-medium text-tly-black ">{label}</label>
      ) : null}
      <button type='button' ref={ref} onClick={toggleDropDown} className='w-full z-10 relative flex justify-between items-center focus:ring-tly-powder focus:border-tly-main
        gap-12 bg-tly-white border border-tly-stroke focus:outline-none rounded-xl focus:ring-4 cursor-pointer p-3'
      >
        <p className='text-tly-gray text-xs'>{selected ? selected?.value : defaultValue}</p>
        {show ? <Icon.ChevronUp size={15} color='gray' /> : <Icon.ChevronDown size={15} color='gray' />}

        <div className={`absolute overflow-auto ${show ? "block" : "hidden"} left-0 top-full min-w-full w-max bg-white shadow-md mt-1 rounded z-40`}>
          <ul className='text-left border border-tly-stroke rounded max-h-[200px] overflow-y-auto'>
            {options?.length ? options?.map( option => (
              <MenuItem 
                key={option.id} 
                value={option.value} 
                id={option.id}
                onSelect={() => handleSelected(option)}
              />
            )): 
            <li className='z-20 px-4 py-2 bg-tly-white hover:bg-tly-powder border-b border-tly-gray font-light text-xs'>No options available</li>
            }
          </ul>
        </div>
      </button>
    </div>
  )
}

export const MenuItem = ({onSelect, value}) => {
  return (
    <li onClick={onSelect} className='z-20 bg-tly-white px-4 py-3 hover:bg-tly-powder border-b border-tly-stroke font-medium text-tly-black text-xs'>{value}</li>
  )
}