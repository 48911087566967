import React from 'react'
import { Outlet } from 'react-router-dom'
import { Footer } from '../components/footer'
import { Navbar } from '../components/navbar'

export const Layout = () => {
  return (
    <div className=''>
      <Navbar />
        <Outlet />
      <Footer />
    </div>
  )
}