import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { DatePicker } from 'rsuite'
import { Button } from '../../components/forms/button'
import { DropDownSelect } from '../../components/forms/dropdown'
import { Input } from '../../components/forms/input'
import { MultiStepContext } from '../../context/multistepContext'
import * as Icon from "react-feather"
import { isNotEmpty } from '../../utils/validation'
import { useForm } from '../../hooks/useForm'
import moment from 'moment'
import { toast, Toaster } from 'sonner'
import { FormHeader } from '../../components/global/formheader'

const degrees = [
  {
    id: 1,
    name: "Bachelor of Medicine (MB)",
    value: "Bachelor of Medicine (MB)"
  },
  {
    id: 2,
    name: "Bachelor of SUrgery (B.S)",
    value: "Bachelor of Surgery (B.S)"
  },
  {
    id: 3,
    name: "Doctor of Medicine(DM)",
    value: "Doctor of Medicine(DM)"
  },
  {
    id: 4,
    name: "Doctor of Osteopathic Medicine (DO)",
    value: "Doctor of Osteopathic Medicine (DO)"
  },
  {
    id: 5,
    name: "Master of Clinical Medicine (MCM)",
    value: "Master of Clinical Medicine (MCM)"
  },
  {
    id: 6,
    name: "Master of Medical Science (MMSc, MMedSc",
    value: "Master of Medical Science (MMSc, MMedSc"
  },
  {
    id: 7,
    name: "Master of Public Health (MPH)",
    value: "Master of Public Health (MPH)"
  },
  {
    id: 8,
    name: "Master of Medicine (MMed)",
    value: "Master of Medicine (MMed)"
  },
  {
    id: 9,
    name: "Master of Philosophy (MPhil)",
    value: "Master of Philosophy (MPhil)"
  },
  {
    id: 10,
    name: "Master Philosophy in Opthmology (MPhO",
    value: "Master Philosophy in Opthmology (MPhO"
  },
  {
    id: 11,
    name: "Master of Public Health and Opthamology (MPHO)",
    value: "Master of Public Health and Opthamology (MPHO)"
  },
  {
    id: 12,
    name: "Master of Surgery (MS, MCh)",
    value: "Master of Surgery (MS, MCh)"
  },
  {
    id: 13,
    name: "Master of Science in Medicine or Surgery (MSc)",
    value: "Master of Science in Medicine or Surgery (MSc)"
  },
  {
    id: 14,
    name: "Doctor of Clinical Medicine (DCM)",
    value: "Doctor of Clinical Medicine (DCM)"
  },
  {
    id: 15,
    name: "Doctor of Clinical Surgery (DClinSurg)",
    value: "Doctor of Clinical Surgery (DClinSurg)"
  },
  {
    id: 16,
    name: "Doctor of Medical Science (DMSc)",
    value: "Doctor of Medical Science (DMSc)"
  },
  {
    id: 17,
    name: "Doctor of Surgery (DS)",
    value: "Doctor of Surgery (DS)"
  },
  {
    id: 18,
    name: "Doctor of Podiatric Medicine (DPM)",
    value: "Doctor of Podiatric Medicine (DPM)"
  },
]

export const ProfessionalInfo = () => {
  
  const { setNewStep, formState, setFormState } = useContext(MultiStepContext)
  
  const [date, setDate] = useState(new Date((formState?.dateofQualification !== "" && formState?.dateofQualification) ?? null ));
  const [fileName, setFileName] = useState('')
  const [qualifyName, setQualifyName] = useState('')

  function getExtension(filename) {
    return filename?.split('/').pop()
  }
  const allowedImageExtensions = ['jpeg', 'jpg', 'png', 'pdf'];;

  const {
    value: enteredLicenseId,
    hasError: licenseIdHasError,
    isValid: licenseIdIsValid,
    valueChangeHandler: licenseIdChangeHandler,
    inputBlurHandler: licenseIdBlurHandler,
    reset: resetLicenseId,
  } = useForm(isNotEmpty, formState?.licenseId)

  const handleUpload = (event) => {
    const license = event.target.files[0];
    if(allowedImageExtensions.includes(getExtension(license?.type)?.toLowerCase())){
      setFileName(() => license?.name)
      setFormState({
        ...formState,
        medLicense: license,
      })
    }
    if(!allowedImageExtensions.includes(getExtension(license?.type)?.toLowerCase())){
      toast.error("File has to be either ('png', 'jpg', 'jpeg', 'pdf' )")
    }
  };

  const handleQualifyDocUpload = (event) => {
    const document = event.target.files[0];
    if(allowedImageExtensions.includes(getExtension(document?.type)?.toLowerCase())){
      setQualifyName(() => document?.name)
      setFormState({
        ...formState,
        qualifyDoc: document,
      })
    }
    if(!allowedImageExtensions.includes(getExtension(document?.type)?.toLowerCase())){
      toast.error("File has to be either ('png', 'jpg', 'jpeg', 'pdf' )")
    }
  };

  const handleNextStep = (e) => {
    e.preventDefault();
    const proInfo = {
      licenseId: enteredLicenseId,
      dateofQualification: moment(date).format("YYYY-MM-DD")
    }
    setNewStep("proInfo", proInfo)
  }
  const handleSelect = (option) => {
    setFormState({
      ...formState,
      degree:option?.value
    })
  }

  let isValid = false;
  if(licenseIdIsValid && formState?.medLicense !== "" && (formState?.degree !== "" && formState?.degree !== undefined ) && (date !== "" && date !== undefined && date !== null) && (formState?.qualifyDoc !== "" && formState?.qualifyDoc !== undefined)){
    isValid = true
  }

  return (
    <div className='w-full flex justify-center flex-col gap-12'>
      <FormHeader
        previous
        heading="Spotlight your expertise"
        subHead="We're eager to learn more about your professional qualifications."
      />
      <form onSubmit={handleNextStep} className='w-full flex items-center justify-center flex-col space-y-8'>
        <section className='w-full grid grid-cols-1 gap-4 space-y-8'>
          <Input 
            name="license"
            label="Medical License ID"
            type="text"
            placeholder="Enter License ID"
            value={enteredLicenseId}
            onChange={licenseIdChangeHandler}
            onBlur={licenseIdBlurHandler}
            required
            error={licenseIdHasError}
            errorText={licenseIdHasError ? "Please Enter a valid License ID" : null}
          />
          <div className='flex items-start flex-col space-y-1'>
            <label>
              Attach medical license
            </label>
            <label className='px-4 py-2.5 w-full flex items-center border border-tly-stroke rounded-xl cursor-pointer'>
              <Input 
                wrapperClassName="hidden"
                name="license"
                type="file"
                onChange={handleUpload}
              />
              <span className='flex items-center space-x-2'>
                <Icon.Paperclip style={{
                  paddingRight: "2px"
                }} color='gray' size={20} />
                <span className='text-sm text-tly-stroke'>{(!!formState?.medLicense && formState?.medLicense !== "" ? fileName : "Attach jpg,png,pdf...") ?? "Attach jpg,png,pdf..."}</span>
              </span>
            </label>
          </div>
        </section>
        <section className='w-full grid grid-cols-1 gap-4 space-y-8'>
          <DropDownSelect
            options={degrees}
            defaultValue="Qualification degree"
            onChange={handleSelect}
            label="Qualification degree"
          />
          <div className='flex items-start flex-col space-y-1'>
            <label>Year of qualification</label>
            <DatePicker 
              size='lg'
              label="Select Date"
              placeholder="Date" 
              value={date} 
              onChange={setDate}
              placement="topStart"
              style={{ borderRadius:"12px", width: "100%", cursor: "pointer", padding: "0px" }} 
            />
          </div>
        </section>

        <section className='w-full'>
          <div className='flex items-start flex-col space-y-1'>
            <label>
              Attach qualification document
            </label>
            <label className='px-4 py-2.5 w-full flex items-center border border-tly-stroke rounded-xl cursor-pointer'>
              <Input 
                wrapperClassName="hidden"
                name="license"
                type="file"
                onChange={handleQualifyDocUpload}
              />
              <span className='flex items-center space-x-2'>
                <Icon.Paperclip style={{
                  paddingRight: "2px"
                }} color='gray' size={20} />
                <span 
                  className='text-sm text-tly-stroke'>{(!!formState?.qualifyDoc && formState?.qualifyDoc !== "" ? qualifyName : "Attach jpg,png,pdf...") ?? "Attach jpg,png,pdf..."}
                </span>
              </span>
              </label>
            </div>
        </section>

        <div className='pt-4 w-full'>
          <Button 
            children="Continue"
            fullWidth={true}
            variant={!isValid ? "default" : "primary"}
            disabled={!isValid}
          />
        </div>
      </form>
      <Toaster richColors position='top-center' />
    </div>
  )
}