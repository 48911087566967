import React, { useContext, useState } from 'react'
import { Button } from '../../components/forms/button'
import { Input } from '../../components/forms/input'
import { MultiStepContext } from '../../context/multistepContext'
import { useForm } from '../../hooks/useForm'
import { confirmPasswordMatches, isNotEmpty, isValidPassword, passwordMatcher } from '../../utils/validation'
import * as Icon from 'react-feather'
import { FormHeader } from '../../components/global/formheader'

export const CreatePassword = () => {

  const { setNewStep, formState, setFormState } = useContext(MultiStepContext)
  const [showPassword, setShowPassword] = useState(false)
  const [touched, setTouched] = useState(false)
  
  const {
    value: enteredPassword,
    hasError: passwordHasError,
    isValid: passwordIsValid,
    valueChangeHandler: passwordChangeHandler,
    inputBlurHandler: passwordBlurHandler,
    reset: resetPassword,
  } = useForm(isValidPassword, formState?.password)

  const {
    value: enteredConfirmPassword,
    hasError: confirmPasswordHasError,
    isValid: confirmPasswordIsValid,
    valueChangeHandler: confirmPasswordChangeHandler,
    inputBlurHandler: confirmPasswordBlurHandler,
    isTouched: confirmIsTouched,
    reset: resetConfirmPassword,
  } = useForm(confirmPasswordMatches(enteredPassword), formState?.password)

  let isValid = passwordMatcher(enteredPassword, enteredConfirmPassword)

  const togglePasswordVisibility = () => {
    setShowPassword(prev => !prev)
  }
  
  const handleNextStep = (e) => {
    e.preventDefault();
    const password = {
      password: enteredPassword
    }
    setNewStep("password", password )
  }

  return (
    <div className='w-full flex items-center justify-center flex-col gap-12'>
      <FormHeader
        previous
        heading="Set your password"
        subHead="It's time to set up your account with a secure password. Choose something memorable but strong to ensure the safety of your account."
      />
      <form onSubmit={handleNextStep} className='w-full flex items-center justify-center flex-col space-y-8'>
        <section className='w-full flex items-start flex-col space-y-4'>
          <Input
            wrapperClassName= "w-full"
            name="password"
            label="Create Password"
            type={showPassword ? 'text' : 'password'}
            placeholder="Enter password"
            value={enteredPassword}
            onChange={passwordChangeHandler}
            onBlur={passwordBlurHandler}
            required
            error={passwordHasError}
            eye
            icon = {
              showPassword ?  <Icon.EyeOff size={15} onClick={togglePasswordVisibility} /> :  <Icon.Eye size={15} onClick={togglePasswordVisibility} />
            }
            errorText= {passwordHasError ? "Password Must contain at least a number, an upperCase character and a special character (!, @, #, $, %, &, *)" : null }
          />
          <Input 
            onFocus={() => {
              setTimeout(() => {
                setTouched(true);
              }, 3000)
            }}
            wrapperClassName= "w-full"
            name="password"
            label="Confirm Password"
            type="password"
            placeholder="confirm password"
            value={enteredConfirmPassword}
            onChange={confirmPasswordChangeHandler}
            onBlur={confirmPasswordBlurHandler}
            required
            error={touched && !confirmPasswordIsValid}
            errorText={touched && !confirmPasswordIsValid ? "Passwords do not match" : null}
          />
        </section>

        <div className='pt-8 w-full'>
          <Button
            children="Continue"
            fullWidth={true}
            disabled={ !isValid}
            variant={!isValid ? "default" : "primary"}
          />
        </div>
      </form>
    </div>
  )
}