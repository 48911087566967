import classNames from 'classnames';
import { useState } from 'react';
import { useLocation } from 'react-router-dom'
import { VerifyIcon } from '../../Assets/Icons/generated'
import { ProgressBar } from './ProgressBar';

/****
To anyone inheriting this or probably my future self, this implementation was perfect for what the previous
UI was. Now due to the design changes which in my opinion has a lot of effect on the previous implementation,
I have crafted whatver CONFUSION, MAGIC and some CRUXIATOS spells from  HOGWARTS beneath to cater to it, while preserving some of the old logic because rewriting would
be crazy at this point.

Commented out the old code for it to make more sense if you figure what was going on. "Check MultiStep context file for clarity"

HAPPY HACKING!!!!!!!
****/


export const MultiStep = ({ steps, currentStep, lastStep, children }) => {

    const { pathname } = useLocation()
    const activePath = pathname.split('/').splice(-1)[0];

    const ObjSteps = Object.values(steps)

    const stepsLength = ObjSteps.length;
    const completedValues = ObjSteps.filter((step) =>  step.complete === true);
    const percentage = 100 * completedValues.length / stepsLength

  return (
    <div className='w-full flex items-center justify-center flex-col space-y-6 py-12'>
        <header className='flex items-center justify-between w-full overflow-auto'>
            {/* {Object.entries(steps)?.map(([key, obj]) => (
                <StepItem
                    key={obj?.id}
                    index={obj?.id} 
                    name={obj?.name}
                    complete={obj?.complete}
                    currentStep={currentStep}
                    activeTab={activePath}
                    lastStep={lastStep}
                    path={obj?.path}
                />
            ))} */}
            <ProgressBar completed={ percentage } />
        </header>
        <main className='w-full flex items-center justify-center flex-col'>
           {children}
        </main>
    </div>
  )
}

export const StepItem = ({ name, complete, currentStep, lastStep, path, completed }) => {

    const completedClass = ("w-full flex items-center text-tly-main whitespace-nowrap")
    const currentStepClass = ("w-full text-tly-main underline whitespace-nowrap")
    const incompleteClass = ("w-full text-tly-gray whitespace-nowrap")

    const StepClass = classNames(complete ? completedClass : path === currentStep ? currentStepClass : incompleteClass)

    return (
        <>
            <span key={path} className='flex items-center grow-0 space-x-1 mx-1'>
                <div className='flex items-center space-x-2'>
                    {complete ? <VerifyIcon /> : null }
                    <span className={StepClass}>
                        {name}
                    </span>
                </div>
            </span>
            <div className={`
                ${complete ? "border-tly-main" : "border-tly-gray" } 
                ${ path === lastStep.path ? "hidden" : "block" } 
                w-full grow border-t-[1px] transition duration-500 ease-in-out`}
            >
            </div>
            {/* <ProgressBar completed={completed} /> */}
        </>

    )
}