import './App.css';
import { Experience } from './Routes/landing/pages/experience';
import { HeroPage } from './Routes/landing/pages/hero';
import { HowItWorks } from './Routes/landing/pages/how';
import { Imagined } from './Routes/landing/pages/imagined';
import { Join } from './Routes/landing/pages/join';
import { Pricing } from './Routes/landing/pages/pricing';
import { StacyPage } from './Routes/landing/pages/stacy';
import { Testimonial } from './Routes/landing/pages/testimonial';
import Video from './Assets/Images/yo.MP4'

function App() {

    return(
        <div>
            <HeroPage />
            <Testimonial />
            <Imagined />
            <StacyPage />
            <Join />
            <HowItWorks />
            <Pricing />
            <Experience />
        </div>
    )
}

export default App;
