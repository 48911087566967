import React from 'react'
import AppStore from '../../../Assets/Images/appstore.png'
import PlayStore from '../../../Assets/Images/googleplay.png'
import mockup1 from '../../../Assets/Images/Mockup.png'
import mockup2 from '../../../Assets/Images/mock2.png'
import mockup3 from '../../../Assets/Images/mock3.png'
import mockup4 from '../../../Assets/Images/mock4.png'
import mockup5 from '../../../Assets/Images/mock5.png'

const MockDetails = [
  {
    id: 1,
    title: 'Secure and Private Healthcare',
    text: `Benefit from secure and confidential medical consultations, 
    ensuring your health information remains private.`,
    image: mockup1,
    top: true
  },
  {
    id: 2,
    title: 'Quick Connections to Trusted Doctors',
    text: `Gain immediate connection to qualified healthcare professionals 
    from the comfort of your home, saving time and travel costs.`,
    image: mockup2,
    bottom: true
  },
  {
    id: 3,
    title: 'Affordable Pricing',
    text: `Receive quality healthcare at competitive prices, 
    with transparent pricing and no hidden costs.`,
    image: mockup3,
    top: true
  },
  {
    id: 4,
    title: 'Empowerment through Knowledge',
    text: `Gain a deeper understanding of your health and treatment options 
    through expert guidance, empowering you to make informed decisions.`,
    image: mockup5,
    top: true
  },
  {
    id: 5,
    title: 'Accessible Anywhere, Anytime',
    text: `Access healthcare expertise 24/7, making it easy to 
    address health concerns wherever you are.`,
    image: mockup4,
    bottom: true
  },
]

export const Imagined = () => {
  return (
    <div id='imagined' className='px-7 md:p-14 flex items-center justify-center'>
      <section className='w-full max-w-screen-2xl grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3'>
        <div className='flex md:items-start md:justify-start flex-col space-y-6'>
          <header className='flex items-center justify-center md:items-start md:justify-start flex-col gap-3'>
            <p className='text-2xl md:text-5xl font-semibold text-center md:text-left'>Healthcare Reimagined, For You</p>
            <span className='text-lg text-tly-gray text-center md:text-left'>Tendarly reimagines healthcare with users at the center, providing simplicity and convenience.</span>
          </header>
          <section className='flex items-center justify-center flex-col space-y-4'>
            <span className='text-base text-tly-gray'>Download the app</span>
            <div className='flex items-center space-x-4'>
              <div>
                <img className='w-28 md:w-full h-10' src={AppStore} alt='app-store-download' />
              </div>
              <div>
                <img className='w-28 md:w-full h-10' src={PlayStore} alt='google-play-download' />
              </div>
            </div>
          </section>
        </div>
        {
          MockDetails?.map(item => (
            <Card
              key={item.id}
              title={item.title}
              text={item.text}
              image={item.image}
              top={item.top}
              bottom={item.bottom}
            />
          ))
        }
      </section>
    </div>
  )
}

export const Card = ({ image, text, title, top, bottom }) => {
  return (
    <div className='overflow-hidden bg-tly-white rounded-3xl sm:h-[480px] border border-tly-stroke flex items-center justify-between flex-col space-y-8'>
      {
        top && 
        <div className='p-8 space-y-2 flex items-center justify-center flex-col text-left'>
          <p className='text-xl font-semibold'>{title}</p>
          <span className='text-base text-tly-gray'>{text}</span>
        </div>
      }
    <div>
      <img
        className='w-full h-full'
        src={image}
        alt='mockup-figure' 
      />
    </div>
      {
        bottom && 
        <div className='p-8 space-y-2 flex items-center justify-center flex-col text-left'>
          <p className='text-xl font-semibold'>{title}</p>
          <span className='text-base text-tly-gray'>{text}</span>
        </div>
      }
    </div>
  )
}