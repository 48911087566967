export const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
export const regEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const regName = /^[A-Za-z- ]+$/;
export const fullNameReg = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
export const regTel = /^[0-9]+$/;

export const isNotEmpty = (value) => value.trim() !== "" && value;
export const isValidName = (value) => (value.trim() !== '' && value.trim() !== null && regName.test(value))
export const isValidFullName = (value) => (value.trim() !== '' && value.trim() !== null && fullNameReg.test(value))
export const isValidEmail = (value) => (value.trim() !== '' && value.trim() !== null && regEmail.test(value))
export const isValidTel = (value) => (value.trim() !== '' && value.trim() !== null && (value.length === 10 || value.length === 11) && regTel.test(value))
export const isValidPassword = (value) => (value.trim() !== '' && value.trim() !== null && passwordRegex.test(value))
export const passwordMatcher = (p1, p2) => {
    if (isNotEmpty(p1) && isNotEmpty(p2))
        return p2 === p1;
}
export const confirmPasswordMatches = (password1) => {
    return  function checkMatches(password2){
        if (isNotEmpty(password1) && isNotEmpty(password2))
        return password2 === password1
    };
}