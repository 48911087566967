import React from 'react'
import Image from '../../../Assets/Images/join.png'
import First from '../../../Assets/Icons/firsticon.svg'
import Second from '../../../Assets/Icons/secondicon.svg'
import Third from '../../../Assets/Icons/thirdicon.svg'
import Fourth from '../../../Assets/Icons/fourthicon.svg'
import Fifth from '../../../Assets/Icons/initicon.svg'
import { LandButton } from '../../../components/forms/landButton'
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from '../../../hooks/useMediaQuery'

const items = [
    {
      id: 1,
      title: 'Expanded Reach',
      text: `Reach a broader patient base, including those in remote areas, 
      expanding your practice's reach beyond geographical boundaries.`,
      icon: First
    },
    {
      id: 2,
      title: 'Flexible Online Practice',
      text: `Enjoy the flexibility of practising medicine online, 
      allowing you to set your hours and better balance your work and personal life.`,
      icon: Fifth
    },
    {
      id: 3,
      title: 'Earn More, Work Less',
      text: `Increase your income by offering virtual consultations and
      leveraging your expertise, all while minimizing overhead costs.`,
      icon: Fourth
    },
    {
      id: 4,
      title: 'Satisfaction in Helping',
      text: `Experience the satisfaction of helping patients overcome 
      health challenges and make informed decisions about their well-being.`,
      icon: Second
    },
    {
      id: 5,
      title: 'Professional Growth',
      text: `Stay at the forefront of healthcare technology, enhancing 
      your skills and adapting to an ever-changing landscape.`,
      icon: Third
    },
]

export const Join = () => {

  const navigate = useNavigate()
  const smallScreen = useMediaQuery('(min-width: 640px)');

  return (
    <div id='join' className='w-full flex items-center justify-center'>
      <div className='w-full max-w-screen-2xl flex flex-col items-center justify-between lg:flex-row lg:items-start lg:justify-start p-8 lg:px-14 lg:space-x-12 space-y-6 lg:space-y-0'>
        <div className='w-full lg:w-1/2'>
          <img className='w-full' src={Image} />
        </div>
        <div className='flex items-start justify-start flex-col space-y-8'>
          <p className='text-2xl md:text-[44px] font-semibold leading-normal'>Join Our Team of Professional Doctors</p>
          {
            items?.map(item => (
              <Card 
                key={item.id}
                title={item.title}
                text={item.text}
                icon={item.icon}
                img={item.img}
              />
            ))
          }
          <div className=' w-full space-y-4 flex items-center justify-center lg:items-start flex-col'>
            <p className='text-lg font-semibold text-tly-gray'>Sign up to bring healthcare to the masses</p>
            <LandButton
              children="Join as a Doctor"
              variant="primary"
              onClick={()=> navigate('/reg', { replace: false })}
              fullWidth={ !smallScreen }
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export const Card = ({ icon, title, text }) => {
    return (
      <div className='flex items-center justify-center space-x-6'>
        {!!icon && <img src={icon} /> }
        <div className='space-y-4'>
          <h1 className='text-base lg:text-xl font-semibold'>{title}</h1>
          <p className='text-sm lg:text-base text-tly-gray'>{text}</p>
        </div>
      </div>
    )
  }