import React from 'react'
import PriceScreen from '../../../Assets/Images/pricingimage.png'
import PRICEBG from '../../../Assets/Images/Payment.png'

export const Pricing = () => {
  return (
    <div id='pricing' className='w-full p-8 md:px-14 flex items-center justify-center'>
        <div className='w-full max-w-screen-2xl flex items-center justify-center'>
            <div
                style={{
                    backgroundImage : `url(${PRICEBG})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    objectFit: "contain",
                }}
                className='flex flex-col-reverse items-center justify-start lg:flex-row lg:justify-between w-full bg-stone-950 rounded-xl px-12'
            >
                <section className='w-full lg:w-1/2 py-8'>
                    <p className='text-2xl font-semibold text-green-800'>Tendarly Pricing</p>
                    <p className='font-semibold text-2xl md:text-[52px] text-white leading-normal'>
                        Pay as you go! Connections on Tendarly cost only ₦1000 with no hidden costs
                    </p>
                </section>
                <div className='flex items-start justify-start overflow-hidden'>
                    <img src={PriceScreen} alt='price-screen' />
                </div>
            </div>
        </div>
    </div>
  )
}