import { useRef } from 'react';
import * as Icon from "react-feather"

export const Input = (props) => {

  const {
    id,
    eye,
    icon,
    wrapperClassName = '',
    placeholder = '',
    label = '',
    type = 'text',
    error = false,
    errorText = '',
    readOnly = false,
    required = false,
    onFocus,
    ...rest
  } = props;

  const inputRef = useRef();

  return (
    <div className={wrapperClassName}>
      <div className={`transition duration-150 ease-in-out space-y-1`}
        onClick={() => inputRef.current.focus()}
      >
        <label
          htmlFor={id}
          className='text-base text-tly-black font-medium pt-1.5 2xl:text-lg'
        >
          {label} {required && <span className='text-tly-error'>*</span>}
        </label>
        <input
          onFocus={onFocus}
          readOnly={readOnly}
          ref={inputRef}
          type={type}
          className={`
            ${error ? 'border border-tly-error focus:ring-tly-error focus:ring-opacity-10 focus:border focus:border-tly-error': 'focus:ring-tly-powder focus:border-tly-main'}
            border ${error ? "boreder-tly-error" : "border-tly-stroke" } placeholder:text-tly-grayhalf relative
            p-3 placeholder:text-sm focus:ring-4 focus:outline-none rounded-xl w-full text-xs text-tly-gray1`}
          id={id}
          placeholder={placeholder}
          {...rest}
        />
        { 
          eye ?
          <span className='absolute pt-3.5 right-12'>
            {icon}
          </span>
          :
          null
        }
      </div>
      {errorText && (
        <div className='flex items-center pt-1'>
          <Icon.AlertCircle size={15} color='red' style={{paddingRight: "3px"}} /> 
          <p className='text-xs 2xl:text-lg text-tly-error'>{errorText}</p>
        </div>
      )}
    </div>
    );
};