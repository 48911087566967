import React from 'react'

const Privacy = () => {
  return (
    <div className='flex items-center justify-center p-14'>
        <div className='w-4/5 flex-col space-y-20'>
            <h1 className='font-bold text-5xl underline'>Privacy Policy</h1>
            <div className='flex items-start justify-start flex-col space-y-6'>
                <h2 className='underline font-semibold text-2xl'>1. Introduction</h2>
                <article className='space-y-6'>
                    <h1>Welcome to <b>Heckerbella Limited</b></h1>
                    <p>
                        Heckerbella Limited (“us”, “we”, “our” or “company”) is committed to ensuring that the
                        privacy and personal information of its clients and employees (data subjects) are protected. We
                        collect and process the personal information of our Data subjects (“you” or “your”) and
                        ensure that your data obtained are used in line with the Company’s privacy principles.
                    </p>

                    <p>
                        The Company operates Tendarly’s Web and Mobile Application (hereinafter referred to as
                        “Service”) and our Privacy Policy governs your visit to Tendarly Mobile Application and
                        explains how we collect, safeguard and disclose information that results from your use of our
                        Service.
                    </p>
                    <p>
                        We use your data to provide and improve the Service. By using the Service, you agree to the
                        collection and use of information in accordance with this policy. Unless otherwise defined in
                        this Privacy Policy, the terms used in this Privacy Policy have the same meaning as in our
                        Terms and Conditions.
                        Our Terms and Conditions (“Terms”) govern all use of our Service and together with the
                        Privacy Policy constitute your agreement with us (“Agreement”).
                    </p>
                </article>
            </div>
            <article className='space-y-6'>
                <h1 className='font-semibold text-2xl underline'>2. Definitions</h1>
                
                <section className='flex items-center justify-between gap-12'>
                    <h1 className='uppercase font-bold text-xl'>
                        Cookies
                    </h1>
                    <span className='flex-2 w-2/3'>
                        means files with small amount of data stored on your device (computer or
                        mobile device) which may include your personal information such as an
                        anonymous unique identifier and can be retrieved subsequently.
                    </span>
                </section>

                <section className='flex items-center justify-between gap-12'>
                    <h1 className='uppercase font-bold text-xl'>
                        DATA
                        CONTROLLER
                    </h1>
                    <span className='flex-2 w-2/3'>
                        means a natural or legal person who (either alone or jointly or in common
                        with other persons) determines the purposes for which and the manner in
                        which any personal data are, or are to be, processed. For the purpose of
                        this Privacy Policy, we are your Data Controller.
                    </span>
                </section>

                <section className='flex items-center justify-between gap-12'>
                    <h1 className='flex-1 uppercase font-bold text-xl'>
                        DATA
                        PROCESSORS (OR
                        SERVICE
                        PROVIDERS)
                    </h1>
                    <span className='w-2/3 flex-2'>
                        means any natural or legal person who processes the data on behalf of the
                        Data Controller. We may use the services of various Service Providers in
                        order to process your data more effectively.
                    </span>
                </section>

                <section className='flex items-center justify-between gap-12'>
                    <h1 className='uppercase font-bold text-xl'>
                        DATA SUBJECT
                    </h1>
                    <span className='flex-2 w-2/3'>
                        means an identifiable person who can be identified directly or indirectly, by
                        reference to an identification number to or more factors specific to the
                        physical, psychological, mental, economic, cultural or social identity. The
                        subject of Personal Data includes the company’s clients, business partners,
                        customers and employees.
                    </span>
                </section>

                <section className='flex items-center justify-between gap-12'>
                    <h1 className='uppercase font-bold text-xl'>
                        END-TO-END ENCRYPTION
                    </h1>
                    <span className='flex-2 w-2/3'>
                        means an identifiable person who can be identified directly or indirectly, by
                        reference to an identification number to or more factors specific to the
                        physical, psychological, mental, economic, cultural or social identity. The
                        subject of Personal Data includes the company’s clients, business partners,
                        customers and employees.
                    </span>
                </section>
                
                <section className='flex items-center justify-between gap-12'>
                    <h1 className='uppercase font-bold text-xl'>
                        PERSONAL DATA
                    </h1>
                    <span className='flex-2 w-2/3'>
                        means data about a living individual who can be identified (or from those
                        and other information either in our possession or likely to come into our
                        possession).
                    </span>
                </section>

                <section className='flex items-center justify-between gap-12'>
                    <h1 className='uppercase font-bold text-xl'>
                        SERVICE
                    </h1>
                    <span className='flex-2 w-2/3'>
                        means Tendarly web and mobile application operated by the Company.
                    </span>
                </section>

                <section className='flex items-center justify-between gap-12'>
                    <h1 className='uppercase font-bold text-xl'>
                        USAGE DATA
                    </h1>
                    <span className='flex-2 w-2/3'>
                        means data collected automatically either generated by the use of the
                        Service or from the Service infrastructure.
                    </span>
                </section>

                <section className='flex items-center justify-between gap-12'>
                    <h1 className='uppercase font-bold text-xl'>
                        USER
                    </h1>
                    <span className='flex-2 w-2/3'>
                        means the individual using our Service. The User corresponds to the Data
                        Subject, who is the subject of Personal Data.
                    </span>
                </section>
            </article>

            <div className='flex items-start justify-start flex-col space-y-6'>
                <h2 className='underline font-semibold text-2xl'>3. Consent</h2>
                <article className='space-y-6'>
                    <p>
                        You accept this Privacy Policy when you give consent upon access to our platform, or
                        use our services, content, features, technologies or functions offered on the Service.
                        This Policy governs the use of the Service unless otherwise agreed through written
                        contract. We may amend this Privacy Policy at any time by posting a revised version
                        on our website.
                    </p>
                </article>
            </div>

            <div className='flex items-start justify-start flex-col space-y-6'>
                <h2 className='underline font-semibold text-2xl'>4. Information Collection and Use</h2>
                <article className='space-y-6'>
                    <p>
                        All personal information collected by the Company is processed in accordance with the extant
                        data protection laws in Nigeria. Furthermore, we collect several types of information for
                        various purposes, specifically, to provide and improve our Service to you.
                    </p>
                </article>
            </div>

            <div className='flex items-start justify-start flex-col space-y-6'>
                <h2 className='underline font-semibold text-2xl'>5. Types of Data Collected</h2>
                <article className='space-y-6'>
                    <p className='flex items-start justify-start space-x-8'>
                        <span className='font-medium'>5.1</span> 
                        <p className=''>
                            <b>Personal Data:</b> While using our Service, we may ask you to provide us with certain personally
                            identifiable information that can be used to contact or identify you (“Personal Data”).
                            Personally identifiable information may include, but is not limited to:
                        </p>
                    </p>

                    <p className='flex items-start justify-start space-x-8'>
                        <span className='font-medium'>5.1.1.</span> 
                        <p>
                            Username, first name and last name date of birth, gender, profile photo, email address,
                            telephone number and address – state, ZIP/Postal Code and city;`
                        </p>
                    </p>

                    <p className='flex items-start justify-start space-x-8'>
                        <span className='font-medium'>5.1.2.</span>
                        <p className=''>
                            Health and wellness data such as height, weight, heart rate, blood pressure level, stress level
                            and sleep related information;
                        </p>
                    </p>

                    <p className='flex items-start justify-start space-x-8'>
                        <span className='font-medium'>5.1.3. </span>
                        <p>
                            Fitness and nutrition information;
                        </p>
                    </p>

                    <p className='flex items-start justify-start space-x-8'>
                        <span className='font-medium'>
                            5.1.4. 
                        </span>
                        <p>
                            We may use your Personal Data to contact you with newsletters, marketing or promotional
                            materials and other information that may be of interest to you. You may opt out of receiving
                            any, or all, of these communications from us by following the unsubscribe link.
                        </p>
                    </p>

                    <p className='flex items-start justify-start space-x-8'>
                        <span className='font-medium'>5.2.</span>
                        <p>
                            <b>Usage Data: </b>in addition to the information you provide directly, we will collect information
                            about your use of the Service through software on your devices. These are:`
                        </p>
                    </p>

                    <p className='flex items-start justify-start space-x-8'>
                        <span className='font-medium'>5.2.1. </span>
                        <p>
                            Information on the type of device, mobile unique ID, IP address of phone, settings and in-app
                            usage information including menus and settings clicked, features used, frequency and duration
                            of use of the Service, crash report data, device’s Internet Protocol address (e.g. IP address),
                            browser type, browser version, the pages of our Service that you visit, unique device identifiers
                            and other diagnostic data;
                        </p>
                    </p>

                    <p className='flex items-start justify-start space-x-8'>
                        <span className='font-medium'>5.3.</span>
                        <p>
                            Connection data: We do not retain your messages in the ordinary course of providing our
                            Service to you. Your messages including media shared during your connections are retained on
                            your device and not typically stored on our servers. Once your messages are delivered, they are
                            deleted from our servers, if not delivered, they remain in an encrypted form on our servers
                            until they are delivered.
                        </p>
                    </p>

                    <p className='flex items-start justify-start space-x-8'>
                        <span className='font-medium'>5.4.</span>
                        <p>
                            Location data: We use this data to provide features of our Service, to improve and customize
                            our Service. This includes Bluetooth and Wi-Fi signals and other information related to your
                            location and with your permission your device’s precise geolocation when you use
                            location-based features. You can enable or disable location services when you use our Service
                            at any time by way of your device settings.
                        </p>
                    </p>

                    <p className='flex items-start justify-start space-x-8'>
                        <span className='font-medium'>5.5.</span>
                        <p>
                            Tracking Cookies Data: We use cookies and similar tracking technologies to track the
                            activity of our Service and retain certain information.
                            Other tracking technologies are also used such as beacons, tags and scripts to collect and track
                            information and to improve and analyze our Service.
                            You may instruct your browser to refuse all cookies or to indicate when a cookie is being
                            sent. However, if you do not accept cookies, you may not be able to use some portions of our
                            Service.
                        </p>
                    </p>
                </article>
            </div>

            <article className='space-y-6'>
                <h2 className="underline font-semibold text-2xl">6. Use of Data</h2>
                <span>Heckerbella Limited uses the collected data for various purposes:</span>

                <section className='space-y-2 flex items-start justify-start flex-col'>
                    <span>(a) to provide and maintain our Service;</span>
                    <span>(b) to notify you about changes to our Service;</span>
                    <span>(c) to allow you to participate in interactive features of our Service when you choose to do so;</span>
                    <span>(d) to provide customer support;</span>
                    <span>(e) to gather analysis or valuable information so that we can improve our Service;</span>
                    <span>(f) to monitor the usage of our Service;</span>
                    <span>(g) to detect, prevent and address technical issues;</span>
                    <span>(h) to fulfill any other purpose for which you provide it;</span>
                    <span>(i) to carry out our obligations and enforce our rights arising from any contracts entered into
                        between you and us, including for billing and collection;
                    </span>
                    <span>(j) to provide you with notices about your account and/or subscription, including expiration and
                        renewal notices, email-instructions, etc.;
                    </span>
                    <span>(k) to provide you with news, special offers and general information about other goods, services
                        and events which we offer that are similar to those that you have already purchased or
                        enquired about unless you have opted not to receive such information;
                    </span>
                    <span>(l) in any other way we may describe when you provide the information;</span>
                    <span>(m) for any other purpose with your consent.</span>
                </section>
           </article>
            
            <article className='space-y-6'>
                <h2 className="underline font-semibold text-2xl">
                    7. Retention and Deletion of Data
                </h2>

                <p className='flex items-start justify-start space-x-8'>
                    <span className='font-medium'>7.1.</span>
                    <p className=''>
                        We will retain your Personal Data only for as long as is necessary for the purposes set out in
                        this Privacy Policy and to the extent necessary to comply with our legal obligations (for
                        example, if we are required to retain your data to comply with applicable laws), resolve
                        disputes, and enforce our legal agreements and policies.
                    </p>
                </p>
                    
                <p className='flex items-start justify-start space-x-8'>
                    <span className='font-medium'>7.2.</span>
                    <p className=''>
                        We will also retain Usage Data for internal analysis purposes. Usage Data is generally retained
                        for a shorter period, except when this data is used to strengthen the security or to improve the
                        functionality of our Service, or we are legally obligated to retain this data for longer time
                        periods.
                    </p>
                </p>

                <p className='flex items-start justify-start space-x-8'>
                    <span className='font-medium'>7.3.</span>
                    <p className=''>
                        You can delete your Tendarly account at any time using the in-app delete feature. When you
                        delete your account, your profile information will also be deleted. However, your data will
                        remain on our servers for a period of 30 (thirty) days before permanent deletion.
                    </p>
                </p>
            </article>

            <article className='space-y-6'>
                <h2 className="underline font-semibold text-2xl">
                    8. Disclosure of Data
                </h2>
                <span>We may disclose personal information that we collect, or you provide for:</span>

                <section className='flex items-start justify-start flex-col space-y-2'>
                    <h3 className='flex items-center space-x-4 font-semibold'>
                        <span>(a)</span>
                        <p>Disclosure for Law Enforcement.</p>
                    </h3>
                    <p>
                        Under certain circumstances, we may be required to disclose your Personal Data if required
                        to do so by law or in response to valid requests by public authorities.
                    </p>
                </section>

                <section className='flex items-start justify-start flex-col space-y-2'>
                    <h3 className='flex items-center space-x-4 font-semibold'>
                        <span>(b)</span>
                        <p>Business Transaction.</p>
                    </h3>
                    <p>
                        If we or our subsidiaries are involved in a merger, acquisition or asset sale, your Personal
                        Data may be transferred.
                    </p>
                </section>

                <section className='flex items-start justify-start flex-col space-y-2'>
                    <h3 className='flex items-center space-x-4 font-semibold'>
                        <span>(c)</span>
                        <p>Other cases. We may disclose your information also:</p>
                    </h3>
                    <p className='flex items-start justify-start flex-col space-y-1'>
                        <span>(i) to our subsidiaries and affiliates;</span>
                        <span>(ii) to contractors, service providers, and other third parties we use to support our business;</span>
                        <span>(iii) to fulfill the purpose for which you provide it;;</span>
                        <span>(iv) for any other purpose disclosed by us when you provide the information;</span>
                        <span>(v) with your consent in any other cases;</span>
                        <span>(vi) if we believe disclosure is necessary or appropriate to protect the rights, property, or
                            safety of the Company, our customers, or others.
                        </span>
                    </p>
                </section>
            </article>

            <article className='space-y-6'>
                <h2 className="underline font-semibold text-2xl">9. Security of Data</h2>
                <p>
                    The security of your data is important to us and to prevent unauthorized access to your
                    information, we have implemented strong controls and security safeguards at the technical and
                    operational levels such as end-to-end encryption. Notwithstanding this, kindly note that no
                    method of transmission over the Internet or method of electronic storage is 100% secure. While
                    we strive to use commercially acceptable means to protect your Personal Data, we cannot
                    guarantee its absolute security.
                </p>
            </article>

            <article className='space-y-6'>
                <h2 className="underline font-semibold text-2xl"> 10. Service Providers</h2>
                <p>
                    We may employ third party companies and individuals to facilitate our Service (“Service
                    Providers”), provide Service on our behalf, perform Service-related services or assist us in
                    analyzing how our Service is used.
                    These third parties have access to your Personal Data only to perform these tasks on our behalf
                    and are obligated not to disclose or use it for any other purpose.
                </p>
            </article>

            <article className='space-y-6'>
                <h2 className="underline font-semibold text-2xl">11. Analytics</h2>
                <p className=''>
                    We may use third-party Service Providers to monitor and analyze the use of our Service. This includes:
                </p>
                <div className='flex items-start justify-start flex-col space-y-1'>
                    <h3 className='font-bold'>Sentry:</h3>
                    <span>
                        Sentry is used to track and monitor the performance of the application. For more information
                        on the privacy practices of Sentry, please visit:
                        https://sentry.io/privacy/original_referrer=https%3A%2F%2Fsentry.io%2Fabout%2F
                    </span>
                </div>

                <div className='flex items-start justify-start flex-col space-y-1'>
                    <h3 className='font-bold'>Mixpanel:</h3>
                    <span>
                        Mixpanel events analytics services are purpose built to track user-interactions with web and
                        mobile applications. you may find more information on the privacy practices of Mixpanel at:
                        https://mixpanel.com/legal/dpa
                    </span>
                </div>
            </article>

            <article className='space-y-6'>
                <h2 className="underline font-semibold text-2xl">12. Links to Other Sites</h2>
                <p>
                    Our Service may contain links to other sites that are not operated by us. If you click a third-party
                    link, you will be directed to that third party's site. We strongly advise you to review the Privacy
                    Policy of every site you visit.
                    We have no control over and assume no responsibility for the content, privacy policies or
                    practices of any third-party sites or services. We shall not accept any responsibility for any loss or
                    damage in whatever manner, howsoever caused, resulting from your disclosure to third parties of
                    personal information.
                </p>
            </article>

            <article className='space-y-6'>
                <h2 className="underline font-semibold text-2xl">13. Children's Privacy</h2>
                <p>
                    Our Services are not intended for use by children under the age of 18 (“Children”).
                    We do not knowingly collect personally identifiable information from Children under 18. If you
                    become aware that a Child has provided us with Personal Data, please contact us. If we become
                    aware that we have collected Personal Data from Children without verification of parental
                    consent, we shall immediately take steps to remove that information from our servers.
                </p>
            </article>

            <article className='space-y-6'>
                <h2 className="underline font-semibold text-2xl"> 14. Changes to This Privacy Policy</h2>
                <p>
                    We may update our Privacy Policy from time to time. We will notify you of any changes by
                    posting the new Privacy Policy on this page.
                    We will let you know via email and/or a prominent notice on our Service, prior to the change
                    becoming effective and update “effective date” at the top of this Privacy Policy.
                    You are advised to review this Privacy Policy periodically for any changes. Changes to this
                    Privacy Policy are effective when they are posted on this page.
                </p>
            </article>

            <article className='space-y-6'>
                <h2 className="underline font-semibold text-2xl">15. Governing Law</h2>
                <p>
                    This Privacy Policy is made pursuant to the Nigerian Data Protection Act (2023) and other
                    relevant Nigerian laws, regulations or international conventions applicable to Nigeria. Where any
                    provision of this Policy is deemed inconsistent with a law, regulation or convention, such
                    provision shall be subject to the overriding law, regulation or convention.
                </p>
            </article>

            <article className='space-y-6'>
                <h2 className="underline font-semibold text-2xl">16. Contact Us</h2>
                <section className='space-y-2 flex items-start justify-start flex-col'>
                    <span>If you have any questions about this Privacy Policy, please contact us:</span>
                    <span>By email: support@heckerbella.com.</span>
                    <span>By visiting this page on our website: https://www.heckerbella.com/contact/.</span>
                    <span>By mail: 9th Floor , Ibukun house, 14 Adetokunbo Ademola street. Victoria Island.</span>
                    <span>By phone: +234 706 735 8926</span>
                </section>
            </article>
        </div>
    </div>
  )
}

export default Privacy