export const ProgressBar = ({ completed }) => {
  
    const containerStyles = {
      height: 9,
      width: '100%',
      backgroundColor: "#BEBEBE",
      borderRadius: 50,
      // margin: 50
    }
  
    const fillerStyles = {
      height: '100%',
      width: `${completed}%`,
      backgroundColor: '#149DD1',
      borderRadius: 'inherit',
      textAlign: 'right'
    }
  
    const labelStyles = {
      padding: '',
      color: '#149DD1',
      fontWeight: 'bold',
      display: 'none'
    }
  
    return (
      <div style={containerStyles}>
        <div style={fillerStyles}>
          <span style={labelStyles}>{`${completed}`}</span>
        </div>
      </div>
    );
};