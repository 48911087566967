import React from 'react'
import Stacy from '../../../Assets/Images/stacy.png'

export const StacyPage = () => {
  return (
    <div className='w-full flex items-center justify-center'>
      <div className='w-full max-w-screen-2xl flex items-center justify-center p-8'>
        <div className='w-full md:w-4/5 flex flex-col-reverse items-center space-y-12 md:space-y-0 md:flex-row justify-between md:space-x-8'>
          <img className='w-full md:w-1/2' src={Stacy} alt='stacy' />
          <div className='flex items-center justify-center md:items-start flex-col space-y-4 w-full md:w-1/2'>
            <p className='text-2xl md:text-[44px] font-semibold'>Meet Stacy</p>
            <p className='text-lg font-normal text-tly-gray text-center md:text-left'>
              When you sign up for Tendarly meet Stacy. Your friendly Healthcare assistant.
              She’s here to guide you along your journey to better health.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}