import React, { useContext, useState } from 'react'
import { Button } from '../../components/forms/button'
import { Input } from '../../components/forms/input'
import { MultiStepContext } from '../../context/multistepContext'
import { useForm } from '../../hooks/useForm'
import { isNotEmpty, isValidEmail, isValidFullName, isValidName, isValidTel } from '../../utils/validation'
import { Modal } from '../../components/global/modal';
import { Link, useOutletContext } from 'react-router-dom'
import axios from "axios";
import * as Icon from 'react-feather'
import { Storage } from '../../App/storage'
import { FormHeader } from '../../components/global/formheader'
import { TextCheckBox } from '../../components/forms/textcheckbox'

export const Referrer = () => {

  const toggleModal = useOutletContext()
  const { formState, setFormState, setNewStep } = useContext(MultiStepContext)

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [responseText, setResponseText] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isChecked, setIsChecked] = useState()

  const handleSuccessModal = () => {
    setShowSuccessModal(prev => !prev)
  }

  const handleErrorModal = () => {
    setShowErrorModal(prev => !prev)
  }

  const {
    value: enteredName,
    hasError: NameHasError,
    isValid: NameIsValid,
    valueChangeHandler: NameChangeHandler,
    inputBlurHandler: NameBlurHandler,
    reset: resetName,
  } = useForm(isValidFullName, formState?.referrerName)

  const {
    value: enteredInstitution,
    hasError: institutionHasError,
    isValid: institutionIsValid,
    valueChangeHandler: institutionChangeHandler,
    inputBlurHandler: institutionBlurHandler,
    reset: resetInstitution,
  } = useForm(isNotEmpty, formState?.referrerInstitution)

  const {
    value: enteredEmail,
    hasError: emailError,
    isValid: emailIsValid,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset: resetEmail
  } = useForm(isValidEmail, formState?.referrerEmail)

  const {
    value: enteredTel,
    hasError: telError,
    isValid: telIsValid,
    valueChangeHandler: telChangeHandler,
    inputBlurHandler: telBlurHandler,
    reset: resetTel
  } = useForm(isValidTel, formState?.referrerPhone)

  let isValid = false;

  if(NameIsValid && emailIsValid && telIsValid && institutionIsValid && isChecked){
    isValid = true
  }

  const handleCheck = (event) => {
    setIsChecked(() => event.target.checked)
  }

  console.log(formState)

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    const referrerDetails = {
      referrerName: enteredName,
      referrerEmail: enteredEmail,
      referrerPhone: enteredTel,
      referrerInstitution: enteredInstitution
    }
    setNewStep("referrer", referrerDetails )
    setFormState({
      ...formState,
      ...referrerDetails
    })
    setIsLoading(true)
    const formData = new FormData()
    formData.append("profile_pic", formState?.profile_pic)
    formData.append("first_name", formState?.firstname)
    formData.append("last_name", formState?.lastname)
    formData.append("email", formState?.email)
    formData.append("birth_date", formState?.dateOfBirth)
    formData.append("gender", formState?.gender)
    formData.append("phone_number", formState?.phone)
    formData.append("password", formState?.password)
    formData.append("medical_license_id", formState?.licenseId)
    formData.append("medical_license_image", formState?.medLicense)
    formData.append("qualification_degree", formState?.degree)
    formData.append("year_of_qualification", formState?.dateofQualification.slice(0, 4))
    formData.append("qualification_document", formState?.qualifyDoc)
    formData.append("referer_name", enteredName ?? formState?.referrerName)
    formData.append("referer_phone", enteredTel ?? formState?.referrerPhone)
    formData.append("referer_email", enteredEmail ?? formState?.referrerEmail)
    formData.append("referer_institution", enteredInstitution ?? formState?.referrerInstitution)

    try {
      const res = await axios.post(`https://api.tendarly.com/api/doctor/auth/signup/`, formData)
      setIsLoading(false)
      handleSuccessModal()
      Storage.clearItem("FORMSTATE")
      Storage.clearItem("STEPS")
    } catch (error){
      setResponseText(error?.response?.data?.message ?? "An error has occurred, please retry your attempt")
      handleErrorModal()
      setIsLoading(false)
    }
    setIsLoading(false)
  }

  return (
    <div className='w-full flex flex-col gap-12 justify-center'>
      <FormHeader
        previous
        heading="Introduce a trusted connection"
        subHead= "We'd love to know if there's someone in the medical field who can vouch for your professional background. Just a friendly step to build a network of trusted connections."
      />
      <form onSubmit={handleSubmitForm} className='w-full flex items-center justify-center flex-col gap-8'>
        <section className='w-full'>
          <Input 
            wrapperClassName = 'w-full'
            name="name"
            label="Name of referral"
            type="text"
            placeholder="Enter referral name"
            value={enteredName}
            onChange={NameChangeHandler}
            onBlur={NameBlurHandler}
            required
            error={NameHasError}
            errorText={NameHasError ? "Please Enter a valid Referral Name" : null}
          />
        </section>
        <section className='w-full space-y-8'>
          <Input 
            name="phone"
            label="Phone Number"
            placeholder="Enter phone number"
            value={enteredTel}
            onChange={telChangeHandler}
            onBlur={telBlurHandler}
            required
            error={telError}
            errorText={telError ? "Please Enter a valid Phone Number" : null}
          />
          <Input 
            name="email"
            label="Email Address"
            type="email"
            placeholder="Enter email"
            value={enteredEmail}
            onChange={emailChangeHandler}
            onBlur={emailBlurHandler}
            required
            error={emailError}
            errorText={emailError ? "Please Enter a valid Email Address" : null}
          />
        </section>

        <section className='w-full'>
          <Input 
            wrapperClassName = 'w-full'
            name="name"
            label="Institution of referral"
            type="text"
            placeholder="Enter referral institution"
            value={enteredInstitution}
            onChange={institutionChangeHandler}
            onBlur={institutionBlurHandler}
            required
            error={institutionHasError}
            errorText={institutionHasError ? "Please Enter a valid Institution" : null}
          />
        </section>

        <section className='w-full text-base text-tly-gray flex items-start flex-col gap-4'>
          <article>
            We prioritize your privacy. Rest assured, any information you provide will be treated with the utmost confidentiality. 
            Your data will be used strictly for verification purposes, ensuring the authenticity of medical professionals on our platform. 
            For more details, please refer to our <Link to="/privacy-policy" target="_blank" rel="noopener noreferrer" className="text-tly-landmain">Privacy Policy</Link>.
          </article>

          <TextCheckBox 
            label='Yes, I Agree'
            checked={isChecked}
            onChange={handleCheck}
          />
        </section>

        <div className='pt-8 w-full'>
          <Button 
            children="Save"
            fullWidth={true}
            isLoading={isLoading}
            disabled={!isValid}
            variant= {!isValid ? 'default' : 'primary'}
          />
        </div>
      </form>
      <Modal
        show={showSuccessModal}
        contentBgColor='bg-tly-white'
        contentWidth='w-full lg:w-2/3'
        headFragment={
        <header className='font-bold text-lg p-2 border-b border-tly-stroke'>
          Details Received Successfully!
        </header>}
        children={<SuccessModal text="Success! Your information has been securely received. Please check your email for a link to verify your details." />}
        position="center"
        padding="4"
        onClose={handleSuccessModal}
        showCloseIcon
        shouldCloseOnEscPress={false}
        shouldCloseOnOverlayClick={false}
      />

      <Modal
        show={showErrorModal}
        contentBgColor='bg-tly-white'
        contentWidth='w-full lg:w-2/3'
        headFragment={
        <header className='flex items-center space-x-2 font-bold text-lg p-2 border-b border-tly-stroke'>
          <Icon.AlertCircle color="red" size={15} />
          <p className='text-tly-error'>An Error Has Occurred</p>
        </header>}
        children={<ErrorModal text={responseText} />}
        position="center"
        padding="4"
        onClose={handleErrorModal}
        showCloseIcon
        shouldCloseOnEscPress={false}
        shouldCloseOnOverlayClick={false}
      />
    </div>
  )
}

export const SuccessModal = ({ text }) => {
  return(
    <div className='flex items-start justify-center p-2 rounded-lg'>
      <p className='text-tly-gray'>{text}</p>
    </div>
  )
}

export const ErrorModal = ({ text }) => {
  return(
    <div className='flex items-start justify-start p-2 rounded-lg'>
      <p className='text-tly-error'>{text}</p>
    </div>
  )
}
