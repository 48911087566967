import React, { useState } from 'react'
import { LandButton } from '../../../components/forms/landButton'
import * as Icon from 'react-feather'
import { useNavigate } from 'react-router-dom'
import Logo from '../../../Assets/Icons/Logo.png'

export const Navbar = () => {

    const navigate = useNavigate()
    const [toggleMenu, setToggleMenu] = useState(false)

    const handleToggle = () => {
        setToggleMenu( prev => !prev )
    }

  return (
    <header id='nav' className='w-full bg-tly-white sticky top-0 left-0 z-10'>
        <nav className='w-full flex items-center justify-between px-7 xl:px-14 py-4'>
            <div className='flex items-center space-x-2'>
                <img className='w-10 h-10' src={Logo} alt='logo' />
                <span className='font-bold text-xl'>Tendarly</span>
            </div>
            <div 
                className={`w-full absolute min-h-[50dvh] left-0 ${toggleMenu ? 'top-[100%] py-7 text-tly-white bg-black bg-opacity-80 backdrop-blur-lg' : 'top-[-600%]' } px-7 gap-8 flex flex-col 
                    xl:w-auto xl:static xl:min-h-fit xl:flex-row xl:items-center xl:justify-between xl:gap-[4vw] text-tly-gray
                    transition-all ease-in-out duration-1000 `}
                >
                <ul className='flex flex-col items-start gap-8 xl:gap-[4vw] xl:flex-row justify-between text-sm'>
                    <li onClick={() => setToggleMenu(false)}>
                        <a href="#hero">Home</a>
                    </li>
                    <li onClick={() => setToggleMenu(false)}>
                        <a href="#imagined">For Users</a>
                    </li>
                    <li onClick={() => setToggleMenu(false)}>
                        <a href="#join">For Doctors</a>
                    </li>
                    <li onClick={() => setToggleMenu(false)}>
                        <a href="#how">How It Works</a>
                    </li>
                    <li onClick={() => setToggleMenu(false)}>
                        <a href="#pricing">Pricing</a>
                    </li>
                    <li onClick={() => setToggleMenu(false)}>
                        <a href="#footer">About Us</a>
                    </li>
                </ul>

                <div className='flex flex-col items-start min-[375px]:items-center min-[375px]:space-x-4 min-[375px]:flex-row min-[375px]:space-y-0 space-y-4'>
                    <LandButton 
                        children="Download the app"
                        variant="primary"
                    />
                    <LandButton 
                        children="Join as a Doctor"
                        variant="default"
                        onClick={() => navigate('/reg', { replace: false })}
                    />
                </div>
            </div>

            <div className='block xl:hidden'>
                {
                    !toggleMenu ?
                    <Icon.Menu onClick={handleToggle} />
                    :
                    <Icon.X onClick={handleToggle} />
                }
            </div>
        </nav>
    </header>
  )
}