import { useNavigate, useRouteError } from "react-router-dom";
import * as Icon from 'react-feather'
import { EmptyState } from "../global/emptyState";
import { Button } from "../forms/button";
// import EmptySearchIcon from "../../Assets/Icons/emptysearch.svg";

export default function ErrorPage() {
  const error = useRouteError();
  const navigate = useNavigate()
  const handleGoBack = () => {
    navigate(-1, {replace: true})
  }

  return (
    <div id="error-page" className="w-full h-screen flex items-center justify-center flex-col">
      <div className="w-1/2">
        <EmptyState
          // icon={EmptySearchIcon}
          text={error.statusText || error.message}
          subtext="Sorry, this page does not exist."
          node={<Button onClick={handleGoBack} children="Go back" leftIcon={<Icon.ArrowLeft size={15} />}/>}
        />
      </div>
    </div>
  );
}