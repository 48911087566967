import React from 'react'
import Image1 from '../../../Assets/Images/testimonial1.png'
import Image2 from '../../../Assets/Images/testimonial2.png'
import Image3 from '../../../Assets/Images/testimonial3.png'
import Image4 from '../../../Assets/Images/testimonial4.png'
import { ImageCard } from '../../../components/Layout/imagecard'

const testimonial = [
  {
    id: 1,
    image: Image1,
    text: `I've recommended Tendarly to all my friends. 
    It's like having a doctor in your pocket. Convenient, 
    efficient, and reliable.`,
    name: 'Vivian Aguoma'
  },
  {
    id: 2,
    image: Image2,
    text: `As a doctor, Tendarly's seamless communication and scheduling 
    tools have made my job easier. It's a win-win for both doctors and patients.`,
    name: 'Dr. Feyisola Arinola`'
  },
  {
    id: 3,
    image: Image3,
    text: ` It's my go-to for quality healthcare. I trust Tendarly with my health.`,
    name: 'Daudu Sunday'
  },
  {
    id: 4,
    image: Image4,
    text: `Tendarly has redefined my practice. 
    It's an incredible platform to engage with patients, 
    and I love the flexibility it offers.`,
    name: 'Dr. Oluchi Uzo'
  },
]

export const Testimonial = () => {
  return (
    <div className='w-full flex items-center justify-between py-12 overflow-auto'>
      <div className='w-full flex items-center justify-between gap-2 overflow-auto resize-none'>
        {
          testimonial?.map(item => (
            <ImageCard 
              key={item.id}
              image={item.image}
              text={item.text}
              name={item.name}
            />
          ))
        }
      </div>
    </div>
  )
}