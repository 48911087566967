import React from 'react'

export const ImageCard = ({ image, text, name }) => {
  return (
    <div className='flex w-[85vw] h-full md:max-w-[350px] md:max-h-[500px] flex-shrink-0 relative group cursor-pointer bg-gradient-to-r from-black to-black overflow-hidden resize-none'>
      <img
        className='w-full h-full group-hover:scale-110 hover:blur-sm group-hover:opacity-40 transition-all ease-in-out duration-1000'
        src={image}
        alt='testimonial-image' 
      />
      <div className="absolute top-0 left-0 right-0 bottom-0 hidden group-hover:flex items-end justify-end p-4">
        <div className='flex items-start space-y-4 flex-col text-tly-white'>
          <span className='text-2xl font-semibold'>{name}</span>
          <p className='text-lg'>{text}</p>
        </div>
      </div>
    </div>
  )
}