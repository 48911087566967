import { useContext } from 'react'
import { Outlet } from 'react-router-dom'
import { MultiStep } from '../../components/global/mulitstep'
import { MultiStepContext } from '../../context/multistepContext'

export const Register = ({ toggleModal }) => {

    const { steps, currentStep, lastStep } = useContext(MultiStepContext)

  return (
    <div className='w-full'>
      <MultiStep 
        steps={steps} 
        currentStep={currentStep}
        lastStep = {lastStep}
      >
        <Outlet context={toggleModal} />
      </MultiStep>
    </div>
  )
}